export enum EnvironmentNames {
  CNCF = 'cncf',
  EME = 'eme',
  IGF = 'igf',
  SWISS = 'swiss',
  GLF = 'glf',
  SINNGEBER = 'sinngeber',
  TOL = 'tol',
  ENVIRONMENT_NAME = 'CONSTANT_ENVIRONMENT_NAME',
}
