enum Urls {
  BASE = '/',
  USER = 'user',
  PAGES = 'pages',

  AUTH = 'auth',
  LOGIN = 'login',
  RESET_PASSWORD = 'reset-password',
  FORGOT_PASSWORD = 'forgot-password',
  CHANGE_PASSWORD = 'change-password',

  PUBLIC = 'public',
  APPLICATION_ASSESSMENT = 'application-assessment',
  CONFIRM = 'confirm',
  CANCEL = 'cancel',

  APP = 'app',
  HOME = 'home',
  FX_CONVERSION = 'fx-conversion',
  ACTIVITY = 'activity',
  CONTRIBUTE = 'contribute',
  DONATE = 'donate',
  MANAGE_RECURRING = 'manage-recurring',
  DONATE_BUTTONS = 'donate-buttons',
  TRANSACTION = 'transaction',
  GRANT = 'grant',
  DISTRIBUTE = 'distribute',
  TRANSFER = 'transfer',
  CHARITY = 'add-charity',
  NO_FUNDS = 'no-funds',
  WELCOME = 'welcome',
  CURRENCY = 'currency',
  ALL = 'all',
  SUMMARY = 'summary',
  CONTRIBUTIONS = 'contributions',
  GRANTS = 'grants',
  TRANSFERS = 'transfers',
  OTHER_ACTIVITIES = 'other-activities',
  PENDING = 'pending',
  FAQS = 'faqs',

  ADMIN = 'admin',
  BANK_INSTRUCTIONS = 'bank-instructions',
  SEARCH_CHARITY = 'search-charity',
  GLOBAL_CONFIG = 'global-config',
}

export default Urls;
