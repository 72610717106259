import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectFundNumber',
})
export class ProjectFundNumberPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): any {
    if (value) {
      const array = value.split(':');
      if (array.length > 1 && array[1]) {
        return array[1];
      }
    }
    return value;
  }
}
