import {Component, OnInit} from '@angular/core';
import {ModalsService} from '../../../_core/services/modals.service';


@Component({
  selector: 'custom-modal',
  templateUrl: './dynamic-content.component.html',
  styleUrls: ['./dynamic-content.component.scss']
})
export class DynamicContentComponent implements OnInit {
  instance;

  constructor(
    private modalsService: ModalsService,
  ) {
  }

  ngOnInit(): void {
    this.instance = this.modalsService.params;
  }

  close() {
    this.modalsService.closeModal();
  }
}
