<div [class.pb-3]="!skipValidation && !hasTemplateValidation">
  <label *ngIf="control.label">
    <span *ngIf="required" class="required">* </span>
    {{ control.label | translate }}
    <span *ngIf="control.maxLength" class="small">({{ 'GENERAL_MAX_CHARS' | translate : { nr: control.maxLength } }})</span>
  </label>
  <div *ngIf="control.helperText" class="small mb-1">
    <i>{{ control.helperText | translate }}</i>
  </div>
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="inputTypes.TEXT">
      <input
        [formControl]="control.auxControl ? control.auxControl : control"
        class="generalInput"
        [maxlength]="maxLength || control.maxLength"
        [placeholder]="(control.placeholder | translate) || ''"
      />
    </ng-container>

    <ng-container *ngSwitchCase="inputTypes.EMAIL">
      <input
        [formControl]="control"
        [maxlength]="maxLength || control.maxLength"
        type="email"
        class="generalInput"
        [placeholder]="(control.placeholder | translate) || ''"
      />
    </ng-container>

    <ng-container *ngSwitchCase="inputTypes.PASSWORD">
      <input
        [formControl]="control"
        type="password"
        autocomplete="new-password"
        class="generalInput"
        [placeholder]="(control.placeholder | translate) || ''"
      />
    </ng-container>

    <ng-container *ngSwitchCase="inputTypes.TEXTAREA">
      <textarea
        class="form-control generalInput"
        rows="5"
        [maxlength]="control.maxLength"
        [formControl]="control"
        [placeholder]="(control.placeholder | translate) || ''"
      ></textarea>
    </ng-container>

    <ng-container *ngSwitchCase="inputTypes.AUTOCOMPLETE">
      <div class="autocomplete w-100">
        <input
          (clickOutside)="hideList()"
          (focus)="displayList(control.auxControl.value)"
          [formControl]="control.auxControl"
          class="generalInput pr-4"
          [placeholder]="(control.placeholder | translate) || ''"
        />
        <i *ngIf="!control.disabled && !control.loading && control.auxControl.value" class="fas fa-times" (click)="clear()"></i>
        <img *ngIf="control.loading" src="assets/spinning_line_dark.svg" alt="loading" />
        <ul class="dropdown" *ngIf="searching">
          <li class="text-center" *ngIf="loadingResults; else showResults">{{ 'GENERAL_LOADER' | translate }}</li>
          <ng-template #showResults>
            <li class="text-center" *ngIf="!dropdownItems.length && control.auxControl.value; else searchResults">
              {{ 'GENERAL_NO_RESULTS' | translate }}
            </li>
            <ng-template #searchResults>
              <li class="searchResult" *ngFor="let item of dropdownItems" (click)="select(item)">
                <div class="pb-1">
                  <div>{{ (item.name | titlecase) || item.symbol }}</div>
                </div>
              </li>
            </ng-template>
          </ng-template>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="inputTypes.DROPDOWN">
      <select class="generalInput input-group-text text-center pointer" [formControl]="control">
        <option #defaultOption *ngIf="control.placeholder" value="" hidden selected>{{ control.placeholder | translate }}</option>
        <option *ngFor="let option of dropdownItems" [ngValue]="option">
          {{ option.name || option.value || option.symbol || option.displayName || option | translate }}
        </option>
      </select>
    </ng-container>

    <ng-container *ngSwitchCase="inputTypes.CURRENCY">
      <div class="currency-field">
        <img *ngIf="control.loading" src="assets/spinning_line_dark.svg" alt="loading" />
        <input
          class="form-control generalInput text-end"
          currencyMask
          step="1"
          [formControl]="control"
          (blur)="focusOut.emit()"
          [options]="{
            prefix: '',
            thousands: digitGroupingSymbol,
            decimal: decimalSymbol,
            precision: 2,
            inputMode: inputMode,
            min: 0,
            max: control.max,
            nullable: true
          }"
        />
      </div>
    </ng-container>
  </ng-container>

  <app-validation-message *ngIf="!skipValidation" [control]="control"></app-validation-message>
</div>
