<div class="modals">
  <div class="modal-content px-3">
    <div class="modal-header py-4 d-block">
      <h4 class="text-center">{{ 'MODALS_EDIT_BUTTONS_TITLE' | translate }}</h4>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <input [(ngModel)]="donateButton.donateButtonName" type="text" class="generalInput my-2" />
      </div>
      <textarea
        [(ngModel)]="donateButton.donateButtonDescription"
        class="form-control generalInput"
        rows="4"
        maxlength="250"
        [placeholder]="'MODALS_EDIT_BUTTONS_PLACEHOLDER' | translate"
      ></textarea>
      <p class="my-2">{{ 'MODALS_EDIT_BUTTONS_REMINDER' | translate }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="lg-button" (click)="edit()">{{ 'MODALS_EDIT_BUTTONS_EDIT' | translate }}</button>
      <button type="button" class="lg-button" (click)="closeModal()">{{ 'GENERAL_CANCEL' | translate }}</button>
    </div>
  </div>
</div>
