<div class="modals">
  <div class="modal-content">
    <div class="modal-header py-4 d-block">
      <h5 class="text-center">{{ 'MODALS_AUTO_LOGOUT_TITLE' | translate }}</h5>
    </div>
    <div class="modal-body text-center">
      <div>{{ 'MODALS_AUTO_LOGOUT_MESSAGE' | translate: { countdown: countdown } }}</div>
    </div>
    <div class="modal-footer justify-content-center">
      <button type="button" class="lg-button" (click)="stay()">{{ 'MODALS_AUTO_LOGOUT_STAY' | translate }}</button>
    </div>
  </div>
</div>
