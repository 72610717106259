import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { FeatureFlagKeys, FeatureFlags } from '../models/FeatureFlags';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private _featureFlags$ = new BehaviorSubject<FeatureFlags>(null);

  constructor() {}

  getFeatureFlags(): BehaviorSubject<FeatureFlags> {
    return this._featureFlags$;
  }

  updateFeatureFlags(flags: FeatureFlags): void {
    this._featureFlags$.next(flags);
  }

  hasFeatureFlag(key: FeatureFlagKeys): Observable<boolean> {
    return this._featureFlags$.pipe(map((flags) => flags?.[key as string]?.enabled));
  }
}
