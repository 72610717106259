import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';
import FundTypes from '../contants/fund-types';
import Urls from '../contants/urls';
import StorageHelper from '../helpers/storage.helper';

@Injectable()
export class FaqsGuard implements CanActivate {
  // prettier ignore
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const fund = StorageHelper.getCurrentFund();
    if (fund && (fund.fundType === FundTypes.CHARITY || fund.fundType === FundTypes.DAF)) {
      return true;
    } else {
      this.router.navigate([Urls.APP]);
      return false;
    }
  }
}
