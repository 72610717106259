<div class="card-custom">
  <div class="card-custom-header" *ngIf="displayHeader">
    <ul class="nav nav-justified">
      <li
        class="nav-item"
        *ngFor="let step of steps"
        [ngClass]="{ active: step.isActive, enabled: !step.isDisabled, disabled: step.isDisabled, completed: isCompleted }"
      >
        <a (click)="goToStep(step)">{{ step.title }}</a>
      </li>
    </ul>
  </div>
  <div class="card-custom-block">
    <ng-content></ng-content>
  </div>
  <div class="card-custom-footer pt-3 pb-3 d-flex justify-content-end" *ngIf="!isCompleted && !isLoading">
    <button type="button" class="lg-button mr-2" (click)="previous()" [hidden]="!hasPrevStep || !activeStep.showPrev">
      {{ 'GENERAL_PREV' | translate }}
    </button>
    <button type="button" class="lg-button" (click)="next()" [disabled]="!activeStep.isValid" [hidden]="!hasNextStep || !activeStep.showNext">
      {{ 'GENERAL_NEXT' | translate }}
    </button>
    <button type="button" class="lg-button" (click)="complete()" [disabled]="!activeStep.isValid" [hidden]="hasNextStep">
      {{ 'GENERAL_SUBMIT' | translate }}
    </button>
  </div>
</div>
