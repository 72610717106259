import { Pipe, PipeTransform } from '@angular/core';

import { applyNumberFormat } from '../../_core/helpers/number-format.helper';
import { GlobalConfigService } from '../../_core/services/global-config.service';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  constructor(private globalConfigService: GlobalConfigService) {}
  transform(value: string, ...args: unknown[]): unknown {
    const { decimalSymbol, digitGroupingSymbol, digitGrouping } = this.globalConfigService.getGlobalConfig().numberFormat;
    return applyNumberFormat(value, decimalSymbol, digitGroupingSymbol, digitGrouping);
  }
}
