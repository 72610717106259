export enum CONTRIBUTION_TYPE {
  BANK_TRANSFER_ONLINE = 'BANK_TRANSFER_ONLINE',
  BANK_TRANSFER_OFFLINE = 'BANK_TRANSFER_OFFLINE',
  CREDIT_CARD = 'CREDIT_CARD',
  DONOR_ADVISED_FUND = 'DONOR_ADVISED_FUND',
  PHYSICAL_CHECK = 'PHYSICAL_CHECK',
  PUBLICLY_TRADED_SECURITIES = 'PUBLICLY_TRADED_SECURITIES',
  OTHER_ASSETS = 'OTHER_ASSETS',
  SEPA_DEBIT = 'SEPA_DEBIT',
}

export enum INPUT_TYPE {
  TEXT,
  EMAIL,
  PASSWORD,
  TEXTAREA,
  AUTOCOMPLETE,
  DROPDOWN,
  CURRENCY,
}
