export enum SERVER_ERROR {
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
  INACTIVE_CONTACT = 'INACTIVE_CONTACT',

  INVALID_PASSWORD_FORMAT = 'INVALID_PASSWORD_FORMAT',
  RESET_PASSWORD_LINK_EXPIRED = 'NOT_PERMITTED',
  RESET_PASSWORD_TOKEN_EXPIRED = 'RESET_PASSWORD_TOKEN_EXPIRED',
  TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
  TOKEN_EXPIRED = 'Token expired',

  INCORRECT_OLD_PASSWORD = 'INVALID_OLD_PASSWORD',
  NEW_PASSWORD_SAME_AS_OLD = 'NEW_PASSWORD_SAME_AS_OLD',

  FUND_NUMBER_NOT_FOUND = 'FUNDNUMBER_NOT_FOUND',
  EXPIRED_FUND = 'EXPIRED_FUND',
  NOT_PERMITTED = 'NOT_PERMITTED',
  SUSPENDED_FUND = 'SUSPENDED_FUND',
  BANK_TRANSFER_INSTRUCTION_NOT_FOUND = 'BANKTRANSFERINSTRUCTION_NOT_FOUND',

  INVALID_CURRENCY = 'INVALID_CURRENCY',
  INVALID_COUNTRY = 'INVALID_COUNTRY',

  NOT_PERMITTED_STRIPE = 'NOT_PERMITTED_STRIPE',
  NOT_PERMITTED_START_DATE = 'NOT_PERMITTED_START_DATE',
  NOT_PERMITTED_END_DATE = 'NOT_PERMITTED_END_DATE',
}
