import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import Urls from '../contants/urls';
import SharedMethodsHelpers from '../helpers/shared-methods.helpers';
import StorageHelper from '../helpers/storage.helper';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (StorageHelper.getToken()) {
      const isEmployee = SharedMethodsHelpers.decodeToken().isEmployee;
      if (isEmployee) {
        return true;
      } else {
        this.router.navigate([Urls.APP]);
        return false;
      }
    } else {
      this.router.navigate([Urls.AUTH]);
      return false;
    }
  }
}
