<ng-container *ngIf="!loading">
  <div class="wrapper">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</ng-container>

<ng-container *ngIf="loading">
  <div class="w-100 h-100 d-flex justify-content-center align-items-center">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-container>
<app-modal-container></app-modal-container>
