import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RECURRENCE_RANGE_END } from './recurring-form.models';

export function createRangeEndValidator(): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    const selectedOption: RECURRENCE_RANGE_END = form.get('rangeOption').value;
    const endDate: Date = form.get('endDate').value;
    const rangeOccurrences: number = form.get('rangeOccurrences').value;

    // Check if there is a date
    if (selectedOption === RECURRENCE_RANGE_END.ENDBY) {
      return endDate !== null ? null : { required: true };
    }

    // Check if number is greater than minimum
    if (selectedOption === RECURRENCE_RANGE_END.ENDAFTER) {
      return rangeOccurrences >= 2 ? null : { notZero: true };
    }

    return selectedOption ? null : { noSelection: true };
  };
}
