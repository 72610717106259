export interface NumberFormatSymbolOption {
  name: string;
  value: string;
}

export const numberFormatSymbols: NumberFormatSymbolOption[] = [
  { name: 'GLOBAL_CONFIG_NUMBER_FORMAT_OPTION_PERIOD', value: '.' },
  { name: 'GLOBAL_CONFIG_NUMBER_FORMAT_OPTION_COMMA', value: ',' },
  { name: 'GLOBAL_CONFIG_NUMBER_FORMAT_OPTION_SPACE', value: ' ' },
  { name: 'GLOBAL_CONFIG_NUMBER_FORMAT_OPTION_APOSTROPHE', value: "'" },
];

export const digitGroupingFormats = ['123456789', '123,456,789', '123456,789', '12,34,56,789'];
