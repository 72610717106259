export interface FeatureFlags {
  [key: string]: {
    enabled: boolean;
    visible: boolean;
  };
}

export enum FeatureFlagKeys {
  RecurringContribution = 'recurringContribution',
  RecurringContributionPublic = 'recurringContributionPublic',
  DAFToProject = 'dafToProject',
  RecurringGrants = 'recurringGrants',
  RecurringDistributions = 'recurringDistributions',
}
