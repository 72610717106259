import { TransactionService } from 'src/app/_core/api/transaction.service';
import { Component, OnInit } from '@angular/core';
import { DonateButton } from 'src/app/_core/models/donateButton';
import { ModalsService } from 'src/app/_core/services/modals.service';
@Component({
  selector: 'app-edit-donate-button',
  templateUrl: './edit-donate-button.component.html',
  styleUrls: ['./edit-donate-button.component.scss']
})
export class EditDonateButtonComponent implements OnInit {
  donateButton: DonateButton;

  constructor(
    private modalService: ModalsService,
    private transactionService: TransactionService
  ) { }

  ngOnInit(): void {
    this.donateButton = JSON.parse(JSON.stringify(this.modalService.params.donateButton));
  }

  edit() {
    this.transactionService.editDonateButton(this.donateButton).subscribe(
      (response: DonateButton) => {
        this.modalService.emitResponse(response);
      }
    );
    this.closeModal();
  }

  closeModal() {
    this.modalService.closeModal();
  }

}
