import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { orderByDropdownOptions } from '../sort-header/sort.model';

@Component({
  selector: 'app-sort-dropdown',
  template: ` <!-- Sort by Section -->
    <div class="mt-2">
      <label class="font-weight-bold">{{ 'ACTIVITY_SORT' | translate }}</label>
      <div class="d-flex" [formGroup]="control">
        <!-- Sort by -->
        <select class="form-control btn btn-default generalDropdown dropdown-toggle mr-3" formControlName="sort">
          <option *ngFor="let sortOption of sortByDropdownOptions" [value]="sortOption.value || sortOption.sortBy">
            {{ sortOption.label || sortOption.title | translate }}
          </option>
        </select>
        <!-- Order by -->
        <select class="form-control btn btn-default generalDropdown dropdown-toggle" formControlName="order">
          <option *ngFor="let orderOption of orderByDropdownOptions" [value]="orderOption.value">
            {{ orderOption.label | translate }}
          </option>
        </select>
      </div>
    </div>`,
  styleUrls: ['./sort-dropdown.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortDropdownComponent {
  @Input() control: FormGroup;
  @Input() sortByDropdownOptions: { label: string; value: string }[] = [];

  orderByDropdownOptions = orderByDropdownOptions;
}
