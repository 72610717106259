import { Pipe, PipeTransform } from '@angular/core';
import { CurrencySet } from '../../_core/models/contributeData';

@Pipe({
  name: 'filterCurrency',
})
export class FilterCurrencyPipe implements PipeTransform {
  transform(value: CurrencySet[], taxDeduction: string, mainCurrency: string): unknown {
    return value?.length ? (taxDeduction ? value.filter((item: CurrencySet) => item.symbol === mainCurrency) : value) : [];
  }
}
