import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  private allITransactionsSource = new Subject<any>();
  private transactionsDisplayedSource = new Subject<any>();


  allTransactions$ = this.allITransactionsSource.asObservable();
  transactionsDisplayed$ = this.transactionsDisplayedSource.asObservable();



  allTransactionsChanged(transaction: any) {
    this.allITransactionsSource.next(transaction);
  }

  transactionsDisplayedChanged(transaction: any) {
    this.transactionsDisplayedSource.next(transaction);
  }

}
