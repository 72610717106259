import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
  transform(enumObject: any): any[] {
    return Object.keys(enumObject).map((key) => ({
      name: key,
      value: enumObject[key],
    }));
  }
}
