import * as moment from 'moment';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../environments/environment';
import { TransactionService } from '../api/transaction.service';
import FundTypes from '../contants/fund-types';
import { Modals } from '../contants/modals';
import { SERVER_ERROR } from '../contants/ServerError';
import SharedMethodsHelpers from '../helpers/shared-methods.helpers';
import StorageHelper from '../helpers/storage.helper';
import { Charity } from '../models/charity.model';
import { Fund, MainAssetSummary } from '../models/fund';
import { ModalsService } from './modals.service';

@Injectable({
  providedIn: 'root',
})
export class FundService {
  private fund = new BehaviorSubject<Fund>(null);
  private _userFunds: Fund[];

  currentFund: Fund;

  fund$ = this.fund.asObservable();

  // prettier ignore
  constructor(private modalsService: ModalsService, private translateService: TranslateService, private transactionService: TransactionService) {}

  initUserFunds(funds: Fund[]): void {
    funds.forEach((fund) => {
      fund.primaryCurrencyId = fund.currency;
      fund.primaryCurrencySymbol = fund.currencySymbol;
    });
    this._userFunds = funds;
  }

  getCurrentFund(): Fund {
    return this.currentFund ? this.currentFund : StorageHelper.getCurrentFund();
  }

  updateBalance({ availableBalance, closingBalance }: MainAssetSummary): void {
    this.currentFund.availableAmount = availableBalance;
    this.currentFund.closingBalance = closingBalance;
    this.fund.next(this.currentFund);
    StorageHelper.saveCurrentFund(this.currentFund);
  }

  fundChanged(fund: Fund): void {
    this.currentFund = fund;
    if (!this.currentFund) {
      this.fund.next(this.currentFund);
      return;
    }
    if (!this.currentFund.fundNumber.includes(':')) {
      this.fund.next(this.currentFund);
      StorageHelper.saveCurrentFund(this.currentFund);
    } else {
      this.getParentFund().subscribe((parentFund: Charity) => {
        this.currentFund.parentFund = parentFund;
        this.fund.next(this.currentFund);
        StorageHelper.saveCurrentFund(this.currentFund);
      });
    }

    if (this.currentFund.fundType === FundTypes.CHARITY) {
      this.checkFundStatus();
    }
  }

  checkFundExpirationDate(): void {
    const renewDate = new Date(Number(this.currentFund.nextRenewDate));
    const daysTillExpire = moment(renewDate).diff(new Date(), 'days');
    if (!isNaN(daysTillExpire) && daysTillExpire <= 30) {
      let displayName = '';
      let infoMessage = '';
      if (daysTillExpire < 0) {
        displayName = 'FUNDS_EXPIRED_FUND_TITLE';
        this.translateService
          .get('FUNDS_EXPIRED_FUND_TEXT', { fund: this.currentFund.fundName, email: environment.mailTo })
          .subscribe((res: string) => {
            infoMessage = res;
          });
      } else if (daysTillExpire >= 0 && daysTillExpire <= 30) {
        const date = renewDate.getMonth() + 1 + '/' + renewDate.getDate() + '/' + renewDate.getFullYear();
        displayName = 'FUNDS_FUND_EXPIRES_SOON_TITLE';
        this.translateService
          .get('FUNDS_FUND_EXPIRES_SOON_TEXT', { fund: this.currentFund.fundName, date: date, email: environment.mailTo })
          .subscribe((res: string) => {
            infoMessage = res;
          });
      }
      this.modalsService.openModal(Modals.DYNAMIC_CONTENT_MODAL, { displayName, infoMessage });
    }
  }

  checkFundStatus(): void {
    if (this.isFundExpired(this.currentFund)) {
      this.translateService
        .get('FUNDS_EXPIRED_FUND_TEXT', { fund: this.currentFund.fundName, email: environment.mailTo })
        .subscribe((infoMessage: string) => {
          this.modalsService.openModal(Modals.DYNAMIC_CONTENT_MODAL, { displayName: 'FUNDS_EXPIRED_FUND_TITLE', infoMessage });
        });
    }
  }

  isFundExpired(fund: Fund): boolean {
    return fund.charityStatus === 'Expired - Active' || fund.charityStatus === 'Expired - Inactive' ? true : false;
  }

  hasBothFundsAndCharity(): boolean {
    if (!this._userFunds) {
      return false;
    }

    return !(
      this._userFunds.every((fund) => fund.fundType === FundTypes.CHARITY) || this._userFunds.every((fund) => fund.fundType === FundTypes.DAF)
    );
  }

  private getParentFund(): Observable<any> {
    const fundNumber = SharedMethodsHelpers.splitAndTakeFirst(this.currentFund.fundNumber, ' : ');
    const parentFund = this._userFunds?.find((innerFund: Fund) => innerFund.fundNumber === fundNumber);
    if (parentFund) {
      const parentExpired = this.isFundExpired(parentFund);
      return of(new Charity(parentFund, parentExpired));
    }
    return this.transactionService.getFundsForTransfer(this.currentFund.cseg5).pipe(
      map((res) => new Charity(res[0])),
      catchError((err) => {
        if (err.error?.message === SERVER_ERROR.EXPIRED_FUND) {
          return of(new Charity(null, true));
        }
        if (err.error?.message === SERVER_ERROR.NOT_PERMITTED) {
          return of(null);
        }
        return throwError(err);
      })
    );
  }

  get userFunds(): Fund[] {
    return this._userFunds;
  }
}
