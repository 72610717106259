import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { INPUT_TYPE } from 'src/app/_core/contants/Contribute';
import { ModalResponse } from 'src/app/_core/contants/modals';
import SharedMethodsHelpers from 'src/app/_core/helpers/shared-methods.helpers';
import { AdminCountry } from 'src/app/_core/models/admin';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-add-country',
  templateUrl: './add-country.component.html',
  styleUrls: ['./add-country.component.scss'],
})
export class AddCountryComponent implements OnInit {
  selectedCountry = -1;
  countries: AdminCountry[] = [];
  listedCountries: AdminCountry[] = [];
  inputTypes = INPUT_TYPE;
  searchCountry: FormControl = new FormControl();
  availableCountries: any[];

  constructor(private modalService: ModalsService) {}

  ngOnInit(): void {
    this.countries = this.modalService.params?.allCountries;
  }

  addCountry(country: AdminCountry) {
    let index = this.countries.map((country) => country.uuid).indexOf(country.uuid);
    if (index > -1) {
      this.listedCountries.push(this.countries[index]);
      this.countries.splice(index, 1);
      this.listedCountries = SharedMethodsHelpers.sortCountries(this.listedCountries);
    }
  }

  removeCountry(uuid: string) {
    let index = this.listedCountries.map((country) => country.uuid).indexOf(uuid);
    if (index > -1) {
      this.selectedCountry = -1;
      this.countries.push(this.listedCountries[index]);
      this.listedCountries.splice(index, 1);
      this.countries = SharedMethodsHelpers.sortCountries(this.countries);
    }
  }

  save() {
    let response = {
      responseType: ModalResponse.ADD_COUNTRY_RESPONSE,
      selectedCountries: this.listedCountries,
    };
    this.modalService.emitResponse(response);
    this.closeModal();
  }

  closeModal() {
    this.modalService.closeModal();
  }

  searchCountryForDropdown(searchText: string) {
    const text = searchText ? searchText : '';
    this.availableCountries = this.countries.filter(
      (country: AdminCountry) => country.name?.indexOf(text.toUpperCase()) > -1 || country.country?.indexOf(text.toUpperCase()) > -1
    );
  }
}
