export const CharityFaqs: string[] = [
  'BALANCE_DIFFERENCE',
  'PRIOR_TO_LAST_MONTH',
  'OTHER_FUNDS',
  'WITHDRAW_FROM_ACCOUNT',
  'USE_CONTRIBUTION',
  'PHONE_PIN',
  'APPROVING_REQUESTS',
  'SECURITY_CALLS',
  'REQUEST_DISTRIBUTION',
  'APPROVING_DISTRIBUTION',
  'MORE_CURRENCIES',
  'REQUEST_TRANSACTION_CURRENCY',
  'SELECT_CONVERSION_AMOUNT',
];

export const DafFaqs: string[] = [
  'BALANCE_DIFFERENCE',
  'PRIOR_TO_LAST_MONTH',
  'OTHER_FUNDS',
  'WITHDRAW_FROM_ACCOUNT',
  'USE_CONTRIBUTION',
  'PHONE_PIN',
  'GRANT_TO_CHARITY',
  'GRANT_COSTS',
  'CHARITY_NOT_LISTED',
  'APPROVING_GRANTS',
  'GRANT_PURPOSE',
  'ANONYMOUS_GIFT',
  'DUE_DILIGENCE',
  'MORE_CURRENCIES',
  'REQUEST_TRANSACTION_CURRENCY',
  'SELECT_CONVERSION_AMOUNT',
];

export const CNCFDafFaqs: string[] = [
  'BALANCE_DIFFERENCE',
  'TRANSFER_AVAILABLE_CASH',
  'PRIOR_TO_LAST_MONTH_DAF',
  'OTHER_FUNDS',
  'WITHDRAW_FROM_ACCOUNT_DAF',
  'USE_CONTRIBUTION',
  'GRANT_TO_CHARITY_DAF',
  'GRANT_COSTS_DAF',
  'CHARITY_NOT_LISTED_DAF',
  'APPROVING_GRANTS_DAF',
  'GRANT_PURPOSE_DAF',
  'ANONYMOUS_GIFT',
  'DUE_DILIGENCE_DAF',
  'MORE_CURRENCIES',
];
