import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  enableNewRelic();
}

function enableNewRelic() {
  const script = document.createElement('script');
  script.src = 'new-relic/new-relic.js';
  script.type = 'text/javascript';
  document.head.appendChild(script);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
