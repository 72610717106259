import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'transform',
})
export class TransformPipe implements PipeTransform {
  transform(value: any, functionName: Function, ...args: any[]): any {
    if (typeof functionName === 'function') {
      return functionName(value, ...args);
    } else {
      console.error(`Function ${functionName} not found on object ${value}`);
      return value;
    }
  }
}
