import { Injectable } from '@angular/core';
import { User } from '../models/user';
import StorageHelper from '../helpers/storage.helper';
import { BehaviorSubject } from 'rxjs';
import Urls from '../contants/urls';
import { Router } from '@angular/router';
import { AuthService } from '../api/auth.service';
import { FundService } from './fund.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _currentUser: User = null;
  private _selectedCurrencyId: string;
  currentUser$ = new BehaviorSubject<User>(null);

  // prettier ignore
  constructor(private router: Router, private authService: AuthService, private fundService: FundService) {}

  getCurrentUser(): User {
    return this._currentUser;
  }

  setCurrentUser(currentUser: User) {
    this._currentUser = currentUser;
    this.currentUser$.next(this._currentUser);
    this._currentUser?.funds?.length && this.fundService.initUserFunds(this._currentUser.funds);
  }

  isLoggedIn(): boolean {
    return !!StorageHelper.getToken();
  }

  logout(redirectUrl?: string) {
    this.isLoggedIn() &&
      this.authService.logout().subscribe(() => {
        this.fundService.fundChanged(null);
        this.killSession(redirectUrl);
      });
  }

  killSession(redirectUrl?: string) {
    StorageHelper.killSession();
    this.setCurrentUser(null);
    const url = redirectUrl ? redirectUrl : Urls.AUTH;
    this.router.navigate([url]);
  }

  setSelectedCurrencyId(currencyId: string): void {
    this._selectedCurrencyId = currencyId;
  }

  get selectedCurrencyId(): string {
    return this._selectedCurrencyId;
  }
}
