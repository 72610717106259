import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectedActivityFundService {
  private selectedFund = new Subject<any>();

  selectedFund$ = this.selectedFund.asObservable();

  changeActivityFund(internalId) {
    this.selectedFund.next(internalId);
  }
}
