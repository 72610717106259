import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appFormatText]',
})
export class FormatTextDirective implements OnInit {
  @Input() text: string;
  @Input() link: string;
  @Input() email: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    const textArray = this.text.split(' ');

    for (const word of textArray) {
      switch (word.toLowerCase()) {
        case 'contact':
          this.createAnchor(word, `mailto:${this.email}`, '', '');
          break;
        case 'here':
          this.createAnchor(word, this.link, '_blank', 'noopener noreferrer');
          break;
        default:
          this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.el.nativeElement.innerHTML + ' ' + word);
      }
    }
  }

  createAnchor(innerHTML: string, href: string, target: string, rel: string) {
    const child = document.createElement('a');
    child.innerHTML = innerHTML;
    child.href = href;
    child.target = target;
    child.rel = rel;
    child.classList.add('table-link');
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.el.nativeElement.innerHTML + ' ');
    this.renderer.appendChild(this.el.nativeElement, child);
  }
}
