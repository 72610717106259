import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class Configuration {
  environmentName = environment.environmentName;
  tenantId = environment.tenantId;
  server = environment.server;
  multiCurrency = environment.multiCurrency;
  primaryColor = environment.primaryColor;
  secondaryColor = environment.secondaryColor;
  ternaryColor = environment.ternaryColor;
  logo = environment.logo;
  publicLogo = environment.publicLogo;
  homeLink = environment.homeLink;
  lightDonateNowButtonLink = environment.lightDonateNowButtonLink;
  darkDonateNowButtonLink = environment.darkDonateNowButtonLink;
  lightDonateNowButtonSrc = environment.lightDonateNowButtonSrc;
  darkDonateNowButtonSrc = environment.darkDonateNowButtonSrc;
  wiringInstructions = environment.wiringInstructions;
  eftInstructions = environment.eftInstructions;
  favicon = environment.favicon;
  contact = environment.contact;
  blog = environment.blog;
  mailTo = environment.mailTo;
  name = environment.name;

  constructor() {}
}
