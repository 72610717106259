import * as moment from 'moment';
import { Moment } from 'moment';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { environment } from '../../../environments/environment';
import { EnvironmentNames } from '../../_core/contants/environments';
import { CustomControl } from '../../_core/models/CustomForms.model';
import { GlobalConfigService } from '../../_core/services/global-config.service';
import { RECURRENCE_PATTERN, RECURRENCE_RANGE_END } from './recurring-form.models';

@Component({
  selector: 'app-recurring-form',
  templateUrl: './recurring-form.component.html',
  styleUrls: ['./recurring-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecurringFormComponent implements OnInit, OnDestroy {
  @Input() control: CustomControl;
  @Input() dateFormat: string;
  @Input() isStripe = false;
  @Input() hideNoEndDate = false;
  @Input() disableStartDate = false;
  @Input() checkGlobalConfig = false;

  private _destroy$ = new Subject<void>();

  minStart = moment().startOf('day').toISOString();
  minEnd: string;
  recurrences: string[];
  endOptions = RECURRENCE_RANGE_END;

  recurrencePattern = this.fb.control(RECURRENCE_PATTERN.WEEKLY, [Validators.required]);
  recurrenceRange = this.fb.group({
    startDate: [new Date(), Validators.required],
    rangeOption: [null, Validators.required],
    endDate: [null],
    rangeOccurrences: [2],
  });

  get isSinngeber(): boolean {
    return environment.environmentName === EnvironmentNames.SINNGEBER;
  }

  get controlRecurrencePattern(): FormControl {
    return this.control?.get('recurrencePattern') as FormControl;
  }

  get controlRecurrenceRange(): FormGroup {
    return this.control?.get('recurrenceRange') as FormGroup;
  }

  get controlRecurrenceStartDate(): FormControl {
    return this.control?.get('recurrenceRange.startDate') as FormControl;
  }

  get controlRecurrenceEndDate(): FormControl {
    return this.control?.get('recurrenceRange.endDate') as FormControl;
  }

  get controlRecurrenceRangeOption(): FormControl {
    return this.control?.get('recurrenceRange.rangeOption') as FormControl;
  }

  get controlRecurrenceRangeOccurrences(): FormControl {
    return this.control?.get('recurrenceRange.rangeOccurrences') as FormControl;
  }

  get rangeEndErrors() {
    return this.control?.get('recurrenceRange')?.errors;
  }

  get maxStartDate() {
    const maxDate = this.addPattern(moment(this.minStart), this.controlRecurrencePattern.value);
    return this.isStripe ? maxDate.subtract(1, 'day').toISOString() : maxDate.toISOString();
  }

  constructor(private fb: FormBuilder, private dateTimeAdapter: DateTimeAdapter<any>, private globalConfigService: GlobalConfigService) {
    const locale = environment.languages[0].split('/')[environment.languages[0].split('/').length - 1].split('-')[0];
    this.dateTimeAdapter.setLocale(locale);
  }

  ngOnInit(): void {
    this.setRecurrencePatterns();
    this.setFormValues();
    this.setControlValueChanges();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  setRecurrencePatterns(): void {
    this.recurrences = this.checkGlobalConfig ? this.globalConfigService.getGlobalConfig().recurrencePattern : Object.keys(RECURRENCE_PATTERN);
  }

  setFormValues(): void {
    this.recurrencePattern.patchValue(this.controlRecurrencePattern?.value);
    this.recurrenceRange.patchValue({
      startDate: moment(this.controlRecurrenceStartDate.value).toISOString(),
      rangeOption: this.controlRecurrenceRangeOption?.value,
      endDate: moment(this.controlRecurrenceEndDate?.value).toISOString(),
      rangeOccurrences: this.controlRecurrenceRangeOccurrences?.value,
    });
    this.setCalendarMinDate(this.recurrencePattern.value);
    if (this.control.disabled) {
      this.recurrencePattern.disable({ emitEvent: false });
      this.recurrenceRange.disable({ emitEvent: false });
    }
  }

  setControlValueChanges(): void {
    this.recurrencePattern.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((recurrencePattern) => {
      this.control.patchValue({ recurrencePattern });
      !this.disableStartDate && this.recurrenceRange.patchValue({ startDate: moment(new Date()).toISOString() });
      this.setCalendarMinDate(recurrencePattern);
    });

    this.recurrenceRange
      .get('startDate')
      .valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe((startDate) => {
        startDate = this.setToCurrentTime(startDate);
        const recurrencePattern = this.recurrencePattern.value;
        const endDateBy = moment(startDate).add(this.getMultiplier(recurrencePattern), this.getAddType(recurrencePattern)).valueOf();

        this.recurrenceRange.get('endDate')?.patchValue(moment(endDateBy).toISOString(), { emitEvent: false });
        this.controlRecurrenceStartDate.patchValue(moment(startDate).valueOf());

        this.setCalendarMinDate(recurrencePattern);
      });

    this.recurrenceRange
      .get('rangeOccurrences')
      .valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe((num) => {
        if (num && !Number.isInteger(num)) {
          this.recurrenceRange.get('rangeOccurrences').patchValue(Math.round(num));
        }
      });

    this.recurrenceRange.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(({ rangeOption, endDate, rangeOccurrences }) => {
      const startDate = this.controlRecurrenceStartDate.value;
      const recurrencePattern = this.recurrencePattern.value;
      const endDateAfterOccurrences = moment(startDate)
        .add(this.getMultiplier(recurrencePattern, rangeOccurrences), this.getAddType(recurrencePattern))
        .valueOf();
      const getEndDate =
        rangeOption === RECURRENCE_RANGE_END.ENDAFTER
          ? endDateAfterOccurrences
          : rangeOption === RECURRENCE_RANGE_END.NOEND
          ? ''
          : this.setToCurrentTime(endDate, startDate).valueOf();

      this.controlRecurrenceRange.patchValue({
        rangeOption,
        endDate: getEndDate,
        rangeOccurrences,
      });
    });
  }

  setCalendarMinDate(recurrencePattern: RECURRENCE_PATTERN): void {
    const startDate = moment(this.controlRecurrenceStartDate.value);
    const endDate = this.addPattern(startDate, recurrencePattern).add(1, 'day');
    this.minEnd = this.setToCurrentTime(endDate).startOf('day').toISOString();
    this.recurrenceRange.patchValue({ endDate: this.minEnd });
  }

  addPattern(date: Moment, rangeOption: RECURRENCE_PATTERN): Moment {
    let numToAdd: number;
    const addType = this.getAddType(rangeOption);

    switch (rangeOption) {
      case RECURRENCE_PATTERN.WEEKLY:
      case RECURRENCE_PATTERN.MONTHLY:
      case RECURRENCE_PATTERN.QUARTERLY:
      case RECURRENCE_PATTERN.ANNUALLY:
        numToAdd = 1;
        break;
      case RECURRENCE_PATTERN.BI_WEEKLY:
        numToAdd = 2;
        break;
      case RECURRENCE_PATTERN.SEMI_ANNUALLY:
        numToAdd = 6;
        break;
    }

    return date.add(numToAdd, addType);
  }

  getAddType(pattern: RECURRENCE_PATTERN): 'weeks' | 'months' | 'quarters' | 'years' {
    switch (pattern) {
      case RECURRENCE_PATTERN.MONTHLY:
      case RECURRENCE_PATTERN.SEMI_ANNUALLY:
        return 'months';
      case RECURRENCE_PATTERN.QUARTERLY:
        return 'quarters';
      case RECURRENCE_PATTERN.ANNUALLY:
        return 'years';
      default:
        return 'weeks';
    }
  }

  getMultiplier(recurrencePattern: RECURRENCE_PATTERN, rangeOccurrences = 2): number {
    switch (recurrencePattern) {
      case RECURRENCE_PATTERN.BI_WEEKLY:
        return 2 * rangeOccurrences;
      case RECURRENCE_PATTERN.SEMI_ANNUALLY:
        return 6 * rangeOccurrences;
      default:
        return 1 * rangeOccurrences;
    }
  }

  formattedDates(date: any): string {
    const selectedDate = moment(date).toISOString();
    return moment(selectedDate).format(this.dateFormat?.toUpperCase());
  }

  // Updates input date to match source to the millisecond
  setToCurrentTime(inputDate: any, sourceDate?: Moment): Moment {
    sourceDate = sourceDate ? moment(sourceDate) : moment(new Date());
    return moment(inputDate)
      .set('hour', sourceDate.get('hour'))
      .set('minute', sourceDate.get('minute'))
      .set('second', sourceDate.get('second'))
      .set('millisecond', sourceDate.get('millisecond'));
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key.length === 1 && /^[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\\/?]*$/.test(event.key)) event.preventDefault();
  }
}
