import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import StorageHelper from '../helpers/storage.helper';
import { Configuration } from '../../app.constants';
import { catchError } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
  private readonly tenantId: string;

  constructor(private configService: Configuration, private injector: Injector) {
    this.tenantId = configService.tenantId;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let newHeaders = req.headers
      .set('Access-Control-Allow-Origin', '*')
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache')
      .set('tenantId', this.tenantId);

    const token = StorageHelper.getToken();

    if (token) {
      newHeaders = newHeaders.set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + token);
    } else {
      newHeaders = newHeaders.set('Authorization', 'Basic dHJ1c3RicmlkZ2U6');
    }

    const clonedReq = req.clone({ headers: newHeaders });
    return next.handle(clonedReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 400:
              if (error?.error?.error_description?.indexOf('Invalid access token') > -1) {
                this.injector.get(UserService).killSession();
                location.reload();
                return of(error);
              } else {
                return throwError(error);
              }
            case 401:
              this.injector.get(UserService).killSession();
              location.reload();
              return of(error);
            case 502:
            // this.router.navigate([Urls.SERVER_ERROR], { skipLocationChange: true });
            default:
              return throwError(error);
          }
        }
      })
    );
  }
}
