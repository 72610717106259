import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../../app.constants';

@Injectable()
export class ApiService {
  private readonly apiUrl;

  constructor(private http: HttpClient, private config: Configuration) {
    this.apiUrl = config.server;
  }

  get(path: string, params = {}, headers = {}) {
    return this.http.get(`${this.apiUrl}${path}`, { params, headers });
  }

  put(path: string, body = {}) {
    return this.http.put(`${this.apiUrl}${path}`, body);
  }

  post(path: string, body = {}, params = {}) {
    return this.http.post(`${this.apiUrl}${path}`, body, { params });
  }

  delete(path, body = {}) {
    return this.http.delete(`${this.apiUrl}${path}`, { body: body });
  }
}
