import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import FundTypes from '../contants/fund-types';
import Urls from '../contants/urls';
import { AccessLevels } from '../contants/user-role';
import StorageHelper from '../helpers/storage.helper';

@Injectable()
export class FundAccessGuard implements CanActivate {
  // prettier ignore
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentFund = StorageHelper.getCurrentFund();
    if (!currentFund) {
      this.router.navigate([Urls.APP]);
      return false;
    }

    const hasAccess =
      [AccessLevels.FULL, AccessLevels.VIEW].includes(currentFund.contactRoleAccessLevel) ||
      (currentFund.fundType === FundTypes.PROJECT && currentFund.contactRoleAccessLevel === AccessLevels.PROJECT_TRANSFER);

    const notExpired = !currentFund.charityStatus || currentFund.charityStatus.toLowerCase().indexOf('expired') < 0;

    if (hasAccess && notExpired) {
      return true;
    }

    this.router.navigate([Urls.APP]);

    return false;
  }
}
