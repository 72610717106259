<div class="modals">
  <div class="modal-content">
    <!-- <div class="modal-header py-4 d-block">
      <h5 class="text-center">{{ instance.displayName | translate }}</h5>
    </div> -->
    <div class="modal-body">
      <h3 class="mb-4">{{ instance.displayName | translate }}</h3>
      <p>{{ instance.infoMessage | translate }}</p>
    </div>
    <div class="d-flex justify-content-end align-items-center px-3 pb-3">
      <button type="button" class="lg-button mr-2" (click)="closeModal()">{{ 'GENERAL_CANCEL' | translate }}</button>
      <button type="button" class="lg-button" (click)="confirm()">{{ 'GENERAL_OK' | translate | uppercase }}</button>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="lg-button" (click)="closeModal()">{{ 'GENERAL_CANCEL' | translate }}</button>
      <button type="button" class="lg-button" (click)="confirm()">{{ 'GENERAL_OK' | translate | uppercase }}</button>
    </div> -->
  </div>
</div>
