import { Component } from '@angular/core';
import { ModalsService } from '../../../_core/services/modals.service';
import { Idle } from '@ng-idle/core';
import { AuthService } from '../../../_core/api/auth.service';

@Component({
  selector: 'auto-logout-modal',
  templateUrl: './auto-logout.component.html',
  styleUrls: ['./auto-logout.component.scss'],
})
export class AutoLogoutComponent {
  countdown: number;

  constructor(private idle: Idle, private modalsService: ModalsService, private authService: AuthService) {
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.countdown = countdown;
    });

    idle.onIdleEnd.subscribe(() => {
      this.idle.watch();
    });
  }

  stay() {
    this.modalsService.closeModal();
    this.idle.watch();
  }
}
