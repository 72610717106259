import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { SortOptions } from '../sort-header/sort-header.component';
import { sortItems } from '../sort-header/sort.helper';

@Injectable()
export class ActivityTablePaginatedSortService {
  currentOptions$ = new BehaviorSubject<SortOptions>(null);
  items: any[];

  init(items: any[]): void {
    this.items = items;
  }

  sortItems(items: any[], { order, sort }: SortOptions): any[] {
    return sortItems(items, { order, sort });
  }
}
