<div class="d-flex flex-column">
  <p class="row ml-4 my-3 font-weight-bold">{{ 'ADMIN_PANEL_SELECT_COUNTRY_TO_CONFIG' | translate }}</p>

  <div class="row col-11 d-flex flex-row ml-4 my-2 selected-countries" *ngIf="listedCountries.length">
    <div *ngFor="let country of listedCountries" class="d-flex justify-content-between ml-2 mt-2 border">
      <p class="m-1">{{ country.name || country.country }}</p>
      <img src="../../../../assets/xmark-solid.svg" class="m-1 item-img pointer" (click)="removeCountry(country.uuid)" />
    </div>
  </div>

  <app-text-input
    class="col-11 ml-2 my-2"
    [type]="inputTypes.AUTOCOMPLETE"
    [autoClear]="true"
    [dropdownItems]="availableCountries"
    [control]="searchCountry"
    (itemSelected)="addCountry($event)"
    (search)="searchCountryForDropdown($event)"
  >
  </app-text-input>

  <div class="row col-11 ml-3 p-0 d-flex justify-content-between">
    <button class="font-weight-bold white-button uppercase" (click)="closeModal()">{{ 'GENERAL_CANCEL' | translate }}</button>
    <button class="lg-button font-weight-bold uppercase mr-4" (click)="save()">{{ 'GENERAL_SAVE' | translate }}</button>
  </div>
</div>
