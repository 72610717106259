import { EditDonateButtonComponent } from './../edit-donate-button/edit-donate-button.component';
import { Component, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { ModalsService } from '../../../_core/services/modals.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { Modals } from '../../../_core/contants/modals';
import { DynamicContentComponent } from '../dynamic-content/dynamic-content.component';
import { AddCharityComponent } from '../add-charity/add-charity.component';
import { AutoLogoutComponent } from '../auto-logout/auto-logout.component';
import { EmbeddedDonateButtonComponent } from '../embedded-donate-button/embedded-donate-button.component';
import { AddCountryComponent } from '../add-country/add-country.component';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { EditTransactionComponent } from '../edit-transaction/edit-transaction.component';

@Component({
  selector: 'app-modal-container',
  template: ``,
})
export class ModalContainerComponent implements OnDestroy {
  modalRef: BsModalRef;
  modalSubscription: Subscription;

  constructor(private modals: ModalsService, private modalService: BsModalService) {
    this.modalSubscription = modals.modal$.subscribe((response) => {
      if (response === 'close') {
        this.closeModal();
        return;
      }

      switch (response) {
        case Modals.DYNAMIC_CONTENT_MODAL:
          this.openModal(DynamicContentComponent, 'modal-dialog-centered');
          break;
        case Modals.ADD_CHARITY:
          this.openModal(AddCharityComponent, 'modal-dialog-centered');
          break;
        case Modals.AUTO_LOGOUT:
          this.openModal(AutoLogoutComponent, 'modal-dialog-centered');
          break;
        case Modals.EMBEDDED_DONATE_BUTTON:
          this.openModal(EmbeddedDonateButtonComponent, 'modal-lg');
          break;
        case Modals.EDIT_DONATE_BUTTON:
          this.openModal(EditDonateButtonComponent, 'modal-lg');
          break;
        case Modals.ADD_COUNTRY:
          this.openModal(AddCountryComponent, 'add-country-modal modal-dialog-centered');
        default:
          break;
        case Modals.CONFIRMATION:
          this.openModal(ConfirmationComponent, 'modal-dialog-centered');
          break;
        case Modals.EDIT_TRANSFER:
          this.openModal(EditTransactionComponent, 'modal-dialog-centered modal-w-lg');
          break;
      }
    });
  }

  openModal(modalComponent, css = '') {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(modalComponent, css ? { class: css } : {});
  }

  closeModal() {
    this.modalRef.hide();
  }

  ngOnDestroy() {
    this.modalSubscription.unsubscribe();
  }
}
