import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalConfigApiService {
  private readonly resourceUrl = '/configuration/frontend/global';

  constructor(private apiService: ApiService) {}

  getConfiguration(): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}`);
  }

  updateConfiguration(payload): Observable<any> {
    return this.apiService.put(`${this.resourceUrl}`, payload);
  }
}
