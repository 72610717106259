import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthService} from './api/auth.service';
import {ApiService} from './services/api.service';
import {Configuration} from '../app.constants';
import {Interceptor} from './interceptors/interceptor';
import {TransactionService} from './api/transaction.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],

  declarations: [
  ],

  providers: [
    ApiService,
    AuthService,
    TransactionService,

    Configuration,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    }
  ],
  exports: [

  ]
})

export class CoreModule {
}
