import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

export type SortOrder = 'ASC' | 'DESC' | 'NONE';

export interface SortOptions {
  order: SortOrder;
  sort: string;
}

@Component({
  selector: 'app-sort-header',
  template: `<div class="d-flex align-items-center sort-header" [ngClass]="alignment">
    <span class="mr-1 user-select-none">{{ label | translate }}</span>
    <div class="d-flex align-items-center position-relative">
      <i
        class="fas fa-sort-up position-absolute"
        [class.inactive-sort]="currentSortAndOrder.order === 'DESC' && currentSortAndOrder.sort === sort"
      ></i>
      <i
        class="fas fa-sort-down position-absolute"
        [class.inactive-sort]="currentSortAndOrder.order === 'ASC' && currentSortAndOrder.sort === sort"
      ></i>
    </div>
  </div>`,
  styleUrls: ['./sort-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortHeaderComponent {
  @Input() label: string;
  @Input() sort: string;
  @Input() align: 'start' | 'end' = 'start';
  @Input() currentSortAndOrder: SortOptions;

  @Output() sortChange: EventEmitter<SortOptions> = new EventEmitter<SortOptions>();

  get alignment(): string {
    return `justify-content-${this.align}`;
  }

  currentOrderBy: SortOrder;

  @HostListener('click') onClick() {
    this.cycleOrderBy();
  }

  cycleOrderBy(): void {
    switch (this.currentOrderBy) {
      case 'ASC':
        this.currentOrderBy = 'DESC';
        break;
      case 'DESC':
        this.currentOrderBy = 'ASC';
        break;
      default:
        this.currentOrderBy = 'ASC';
        break;
    }

    this.sortChange.emit({ sort: this.sort, order: this.currentOrderBy });
  }
}
