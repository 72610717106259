import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlSegment, Route } from '@angular/router';
import Urls from '../contants/urls';
import StorageHelper from '../helpers/storage.helper';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import { AuthService } from '../api/auth.service';
import { User } from '../models/user';
import SharedMethodsHelpers from '../helpers/shared-methods.helpers';

@Injectable()
export class NoAuthGuard implements CanActivate {
  // prettier ignore
  constructor(private router: Router, private authService: AuthService, private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.hasAccess(state);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | boolean {
    return this.hasAccess(segments);
  }

  hasAccess(urlData: RouterStateSnapshot | UrlSegment[]): Observable<boolean> | boolean {
    let url: string, lastParam: string;
    if (urlData instanceof RouterStateSnapshot) {
      url = urlData.url;
      lastParam = url.split('/').reverse()[0];
    } else if (urlData[0] instanceof UrlSegment) {
      url = urlData.join('/');
      lastParam = urlData[urlData.length - 1].path;
    }

    if (StorageHelper.getToken()) {
      this.authService.getUserDetails().subscribe((user: User) => {
        if (Object.values(Urls).findIndex((url) => url === lastParam) < 0) {
          this.userService.logout(url);
          return true;
        }
        if (user?.email) {
          if (SharedMethodsHelpers.decodeToken().isEmployee && !user.funds.length) {
            this.router.navigate([`${Urls.APP}/${Urls.ADMIN}`]);
            return false;
          }
          this.router.navigate([Urls.APP]);
          return false;
        }
        return true;
      });
    } else {
      return true;
    }
  }
}
