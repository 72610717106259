import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { TitleData } from '../models/GenericObjects';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private titleSource = new ReplaySubject<TitleData>(1);

  title$ = this.titleSource.asObservable();

  titleChanged(title: string, param?: string) {
    this.titleSource.next({ text: title, param: param });
  }
}
