enum PageTitles {
  LOG_IN = 'PAGE_TITLE_LOG_IN',

  FORGOT_PASSWORD = 'PAGE_TITLE_FORGOT_PASSWORD',
  RESET_PASSWORD = 'PAGE_TITLE_RESET_PASSWORD',
  CHANGE_PASSWORD = 'PAGE_TITLE_CHANGE_PASSWORD',
  CHANGE_PASSWORD_SET_PIN = 'PAGE_TITLE_CHANGE_PASSWORD_SET_PIN',
  SET_PIN = 'PAGE_TITLE_SET_PIN',

  HOME = 'PAGE_TITLE_HOME',
  CONTRIBUTION_PRIVATE = 'PAGE_TITLE_CONTRIBUTION_PRIVATE',
  CONTRIBUTION_PUBLIC = 'PAGE_TITLE_CONTRIBUTION_PUBLIC',
  APPLICATION_ASSESSMENT = 'PAGE_TITLE_PPLICATION_ASSESSMENT',
  GIVING_TO = 'PAGE_TITLE_GIVING_TO',
  THANK_YOU = 'PAGE_TITLE_THANK_YOU',
  PAYMENY_FAILED = 'PAGE_TITLE_PAYMENT_FAILED',
  GRANT_DAF = 'PAGE_TITLE_GRANT_DAF',
  GRANT_CHARITY = 'PAGE_TITLE_GRANT_CHARITY',
  ADD_CHARITY = 'PAGE_TITLE_ADD_CHARITY',
  SEARCH_CHARITY = 'PAGE_TITLE_SEARCH_CHARITY',
  TRANSFER = 'PAGE_TITLE_TRANSFER',
  FX_CONVERSION = 'PAGE_TITLE_FX_CONVERSION',
  ACTIVITY = 'PAGE_TITLE_ACTIVITY',
  DONATE_BUTTONS = 'PAGE_TITLE_DONATE_BUTTONS',
  FAQS = 'PAGE_TITLE_FAQS',

  ADMIN_WELCOME = 'PAGE_TITLE_ADMIN_WELCOME',
  SELECT_CURRENCY = 'PAGE_TITLE_SELECT_CURRENCY',
  CONFIG_BANK_INSTRUCTIONS = 'PAGE_TITLE_CONFIG_BANK_INSTRUCTIONS',
  GLOBAL_CONFIG = 'PAGE_TITLE_GLOBAL_CONFIG',

  NOT_FOUND = 'PAGE_TITLE_NOT_FOUND',
  CANNOT_ACCESS = 'PAGE_TITLE_CANNOT_ACCESS',
}

export default PageTitles;
