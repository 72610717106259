<div class="modals">
  <div class="modal-content">
    <div class="modal-header py-4 d-block">
      <h5 class="text-center">{{ instance.displayName | translate }}</h5>
    </div>
    <div class="modal-body">
      {{ instance.infoMessage | translate }}
    </div>
    <div class="modal-footer">
      <button type="button" class="lg-button" (click)="close()">{{ 'GENERAL_OK' | translate | uppercase }}</button>
    </div>
  </div>
</div>
