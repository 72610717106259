import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../_core/services/title.service';
import Urls from '../../_core/contants/urls';
import { Router } from '@angular/router';
import PageTitles from '../../_core/contants/page-titles';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  constructor(private router: Router, private titleService: TitleService) {}

  ngOnInit(): void {
    this.titleService.titleChanged(PageTitles.NOT_FOUND);
  }

  goHome() {
    this.router.navigate([Urls.APP]);
  }
}
