import * as moment from 'moment';

export const getRangePatterns = (startDate: any, endDate: any) => {
  const numberOfWeeks = Math.ceil(moment.duration(endDate - startDate).asWeeks());
  const numberOfBiWeeks = Math.ceil(numberOfWeeks / 2);
  const numberOfMonths = Math.ceil(moment.duration(endDate - startDate).asMonths());
  const numberOfQuarters = Math.ceil(numberOfMonths / 3);
  const numberOfSemiAnnuals = Math.ceil(numberOfMonths / 6);
  const numberOfAnnuals = Math.ceil(numberOfMonths / 12);

  return {
    WEEKLY: numberOfWeeks,
    BI_WEEKLY: numberOfBiWeeks,
    MONTHLY: numberOfMonths,
    QUARTERLY: numberOfQuarters,
    SEMI_ANNUALLY: numberOfSemiAnnuals,
    ANNUALLY: numberOfAnnuals,
  };
};
