import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Fund } from '../../_core/models/fund';
import { UserService } from '../../_core/services/user.service';
import { FundService } from '../../_core/services/fund.service';
import { Subscription } from 'rxjs';
import { TitleService } from '../../_core/services/title.service';
import { Router } from '@angular/router';
import Urls from '../../_core/contants/urls';
import FundTypes from '../../_core/contants/fund-types';
import { SelectedActivityFundService } from 'src/app/_core/services/selected-activity-fund.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TitleData } from 'src/app/_core/models/GenericObjects';
import { environment } from 'src/environments/environment';
import { EnvironmentNames } from 'src/app/_core/contants/environments';

@Component({
  selector: 'funds-header',
  templateUrl: './funds.component.html',
  styleUrls: ['./funds.component.scss'],
})
export class FundsComponent implements OnInit, OnDestroy {
  @Input() isPublic: boolean = true;

  fundChangedEventSub: Subscription;
  titleChangeEventSub: Subscription;
  breakpointSubscription: Subscription;

  funds: Fund[];
  currentFund: Fund;
  fundTypes = FundTypes;
  currentPageTitleData: TitleData;

  isMobile: boolean;
  fundsOpen = false;
  isAdminPanel = false;
  isSinngeber: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
    private fundService: FundService,
    private titleService: TitleService,
    private selectedActivityFund: SelectedActivityFundService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.selectedActivityFund.selectedFund$.subscribe((internalId) => {
      this.funds.filter((fund) =>
        fund.cseg5 === internalId
          ? this.changeFundOrRedirect(fund)
          : fund.projects.length > 0
          ? fund.projects.filter((project) => (project.cseg5 === internalId ? this.changeFundOrRedirect(project) : undefined))
          : undefined
      );
      this.router.navigate([`${Urls.APP}/${Urls.ACTIVITY}/${Urls.SUMMARY}`]);
    });

    this.fundChangedEventSub = this.fundService.fund$.subscribe((fund: Fund) => {
      this.currentFund = fund;
      this.funds = this.userService.getCurrentUser()?.funds || [];
    });

    this.titleChangeEventSub = this.titleService.title$.subscribe((data: TitleData) => {
      if (data) {
        this.isAdminPanel = this.router.url.indexOf(Urls.ADMIN) > -1;
        this.currentPageTitleData = data;
      }
    });

    this.breakpointSubscription = this.breakpointObserver.observe(['(max-width: 1100px)']).subscribe((result) => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
    this.isAdminPanel = this.router.url.indexOf(Urls.ADMIN) > -1;
    this.isSinngeber = environment.environmentName === EnvironmentNames.SINNGEBER;
    this.funds = this.userService.getCurrentUser()?.funds || [];
  }

  ngOnDestroy(): void {
    this.fundChangedEventSub.unsubscribe();
    this.titleChangeEventSub.unsubscribe();
    this.breakpointSubscription.unsubscribe();
  }

  changeFundOrRedirect(fund: Fund) {
    this.fundService.fundChanged(fund);
    this.goHome();
  }

  goHome() {
    this.router.navigate([Urls.APP]);
  }
}
