import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uppercaseFirstLetter',
})
export class UppercaseFirstLetterPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value) {
      return value.charAt(0) + value.slice(1).toLowerCase();
    }
    return value;
  }
}
