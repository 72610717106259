import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Data } from '@angular/router';
import StorageHelper from '../helpers/storage.helper';
import Urls from '../contants/urls';
import SharedMethodsHelpers from '../helpers/shared-methods.helpers';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  // @ts-ignore
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | boolean {
    const redirectUrl = segments.map((segment) => segment.path).join('/');
    return this.canAccess(route.data, redirectUrl);
  }

  // @ts-ignore
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const redirectUrl = state.url;
    return this.canAccess(route.data, redirectUrl);
  }

  canAccess(routeData: Data, redirectUrl: string) {
    if (StorageHelper.getToken()) {
      if (!routeData) {
        return true;
      }
      if (routeData.environments && routeData.environments.indexOf(environment.environmentName) > -1) {
        return true;
      }
      if (routeData.roles && routeData.roles.indexOf(SharedMethodsHelpers.decodeToken().authorities[0]) === -1) {
        this.router.navigate([`${Urls.APP}/${Urls.CHANGE_PASSWORD}`]);
        return false;
      }
      return true;
    }
    this.router.navigate([Urls.AUTH], { queryParams: { returnUrl: redirectUrl } });
    return false;
  }
}
