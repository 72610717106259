import { Component, OnDestroy, OnInit } from '@angular/core';
import StorageHelper from './_core/helpers/storage.helper';
import { UserService } from './_core/services/user.service';
import { FundService } from './_core/services/fund.service';
import { User } from './_core/models/user';
import { AuthService } from './_core/api/auth.service';
import { Router } from '@angular/router';
import Urls from './_core/contants/urls';
import { Fund } from './_core/models/fund';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ModalsService } from './_core/services/modals.service';
import { Modals } from './_core/contants/modals';
import { Subscription } from 'rxjs';
import SharedMethodsHelpers from './_core/helpers/shared-methods.helpers';
import { Configuration } from './app.constants';
import { Role } from './_core/contants/user-role';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { GlobalConfigApiService } from './_core/api/global-config.service';
import { ConfigSetting } from './pages/admin-welcome/global-config/global-config.model';
import { GlobalConfigService } from './_core/services/global-config.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  currentUserSub: Subscription;
  lastPing?: Date = null;
  tokenInfo: any;
  pinAndPassword: any;
  loading: boolean;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private userService: UserService,
    private fundService: FundService,
    private authService: AuthService,
    private modalsService: ModalsService,
    private config: Configuration,
    private globalConfigApiService: GlobalConfigApiService,
    private globalConfigService: GlobalConfigService,
    private translate: TranslateService,
    private title: Title
  ) {
    this.translate.use(environment.languages[0]);

    idle.setIdle(600);
    idle.setTimeout(15);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onTimeout.subscribe(() => {
      this.modalsService.closeModal();
      this.userService.logout();
    });

    idle.onIdleStart.subscribe(() => {
      this.modalsService.openModal(Modals.AUTO_LOGOUT);
    });

    keepalive.interval(15);
    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.currentUserSub = this.userService.currentUser$.subscribe((user) => (user ? idle.watch() : idle.stop()));

    this.changeTheme();
    this.changeIcon();
  }

  ngOnInit(): void {
    this.getGlobalConfiguration();
  }

  getGlobalConfiguration(): void {
    this.loading = true;
    this.globalConfigApiService.getConfiguration().subscribe({
      next: (res) => {
        this.globalConfigService.updateGlobalConfig(new ConfigSetting(res.response));
        this.handleRole();
        this.title.setTitle(res.response?.applicationTitle.trim() || 'Financial Management System');
      },
      error: () => {
        this.translate.get('TOASTER_BASIC_ERROR').subscribe((text: string) => {
          alert(text);
        });
        this.loading = false;
      },
    });
  }

  handleRole(): void {
    if (StorageHelper.getToken()) {
      this.tokenInfo = SharedMethodsHelpers.decodeToken();
      const userName = StorageHelper.getUserName();
      this.pinAndPassword = SharedMethodsHelpers.extractPinAndPasswordDetails(this.tokenInfo);
      switch (this.tokenInfo.authorities[0]) {
        case Role.USER:
        case Role.MASTER:
          this.getUserDetails();
          break;
        case Role.TEMPORARY_USER:
          if (!this.pinAndPassword.goToChangePassword) {
            this.userService.setCurrentUser(this.pinAndPassword);
          } else {
            this.userService.setCurrentUser({
              email: userName,
              ...this.pinAndPassword.details,
            });
          }
          this.loading = false;
          break;
        case Role.ROLE_ADMIN:
          this.userService.setCurrentUser(this.tokenInfo.user_name);
          this.loading = false;
          break;
        default:
          break;
      }
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.currentUserSub.unsubscribe();
  }

  getUserDetails() {
    this.authService.getUserDetails().subscribe(
      (user: User) => {
        const userInfo = { ...user, ...this.pinAndPassword };
        this.userService.setCurrentUser(userInfo);
        this.handleFunds(user.funds);
        this.loading = false;
      },
      () => {
        StorageHelper.killSession();
        this.loading = false;
        this.router.navigate([Urls.AUTH]);
      }
    );
  }

  handleFunds(funds: Fund[]) {
    if (!funds.length) {
      if (SharedMethodsHelpers.decodeToken().isEmployee) {
        if (this.router.url.includes(Urls.ADMIN)) return;
        this.router.navigate([`${Urls.APP}/${Urls.ADMIN}`]);
        return;
      }
      this.router.navigate([`${Urls.APP}/${Urls.NO_FUNDS}`]);
      return;
    }

    if (StorageHelper.getCurrentFund()) {
      this.fundService.fundChanged(StorageHelper.getCurrentFund());
    } else {
      this.fundService.fundChanged(funds[0]);
    }
  }

  changeTheme() {
    document.documentElement.style.setProperty('--main-color', this.config.primaryColor);
    document.documentElement.style.setProperty('--secondary-color', this.config.secondaryColor);
    document.documentElement.style.setProperty('--ternary-color', this.config.ternaryColor);
  }

  changeIcon() {
    this.favIcon.href = this.config.favicon;
  }
}
