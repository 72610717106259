export enum IntervalOption {
  ALL_TIME = 'ACTIVITY_DATE_ALL_TIME',
  LAST_30_DAYS = 'ACTIVITY_DATE_LAST_30_DAYS',
  LAST_90_DAYS = 'ACTIVITY_DATE_LAST_90_DAYS',
  THIS_MONTH = 'ACTIVITY_DATE_THIS_MONTH',
  LAST_MONTH = 'ACTIVITY_DATE_LAST_MONTH',
  THIS_YEAR = 'ACTIVITY_DATE_THIS_YEAR',
  LAST_YEAR = 'ACTIVITY_DATE_LAST_YEAR',
  CUSTOM = 'ACTIVITY_DATE_CUSTOM',
}

export const INTERVAL_OPTIONS: IntervalOption[] = [
  IntervalOption.ALL_TIME,
  IntervalOption.LAST_30_DAYS,
  IntervalOption.LAST_90_DAYS,
  IntervalOption.THIS_MONTH,
  IntervalOption.LAST_MONTH,
  IntervalOption.THIS_YEAR,
  IntervalOption.LAST_YEAR,
  IntervalOption.CUSTOM,
];
