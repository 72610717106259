import { ModalsService } from './../../../_core/services/modals.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-embedded-donate-button',
  templateUrl: './embedded-donate-button.component.html',
  styleUrls: ['./embedded-donate-button.component.scss']
})
export class EmbeddedDonateButtonComponent implements OnInit {
  public buttonHtmlCode: string;

  link: string;
  lightButtonPath = 'https://5432341.app.netsuite.com/core/media/media.nl?id=425986&c=5432341&h=1mbtBQFEwVAtBRaoFfeWHwwCsvUzId6x_hwoHh_JVOsLdNyO';
  darkButtonPath = 'https://5432341.app.netsuite.com/core/media/media.nl?id=425985&c=5432341&h=v7eBj6dwbUKmDb1S9y6NJOyGV_WwAxNMQimOf1HlbkAsGVtm';

  constructor(
    private modalService: ModalsService
    ) { }

  ngOnInit(): void {
    this.link = this.modalService.params.donateLink;
  }

  close() {
    this.modalService.closeModal();
  }

  onSelectButton(event) {
    if (event === 'light') {
      this.buttonHtmlCode = `<a target="_blank" href="${this.link}"><img src="${this.lightButtonPath}"/></a>`;
    } else {
      this.buttonHtmlCode = `<a target="_blank" href="${this.link}"><img src="${this.darkButtonPath}"/></a>`;
    }
  }


}
