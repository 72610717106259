import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ModalsService } from 'src/app/_core/services/modals.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationComponent implements OnInit {
  instance;

  constructor(private modalService: ModalsService) {}

  ngOnInit(): void {
    this.instance = this.modalService.params;
  }

  confirm() {
    this.modalService.emitResponse(true);
    this.closeModal();
  }

  closeModal() {
    this.modalService.closeModal();
  }
}
