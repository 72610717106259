import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFloor',
})
export class ToFloorPipe implements PipeTransform {
  transform(value: number): unknown {
    return Math.floor(value);
  }
}
