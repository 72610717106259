<div class="footer">
  <div class="d-flex justify-content-center">
    <div class="mr-3" *ngIf="terms">
      <a [href]="terms" target="_blank">{{ (isCncf ? 'FOOTER_DISCLAIMER' : 'FOOTER_TERMS') | translate }}</a>
    </div>
    <div *ngIf="privacyPolicy">
      - &nbsp; &nbsp;
      <a [href]="privacyPolicy" target="_blank">{{ 'FOOTER_PRIVACY' | translate }}</a>
    </div>
  </div>
  <div class="text-center font-weight-bold" style="font-size: 14px">
    {{ 'FOOTER_COPYRIGHT' | translate: { year: year } }}
  </div>
</div>
