import { Component, OnInit } from '@angular/core';
import { ModalsService } from '../../../_core/services/modals.service';
import { environment } from 'src/environments/environment';
import { EnvironmentNames } from 'src/app/_core/contants/environments';

@Component({
  selector: 'add-charity-modal',
  templateUrl: './add-charity.component.html',
  styleUrls: ['./add-charity.component.scss'],
})
export class AddCharityComponent implements OnInit {
  hasFootNote: boolean;
  hasSecondaryText: boolean;

  constructor(private modalsService: ModalsService) {}

  ngOnInit(): void {
    this.hasFootNote = environment.environmentName !== EnvironmentNames.CNCF && environment.environmentName !== EnvironmentNames.IGF;
    this.hasSecondaryText = environment.environmentName === EnvironmentNames.CNCF;
  }

  close() {
    this.modalsService.closeModal();
  }
}
