import { AdminGuard } from './_core/guards/admin.guard';
import {NgModule} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import {AppComponent} from './app.component';
import {CoreModule} from './_core/core.module';
import {SharedModule} from './_shared/shared.module';

import {AppRoutingModule} from './app-routing.module';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuard} from './_core/guards/auth.guard';
import {NoAuthGuard} from './_core/guards/no-auth.guard';
import {CanAccessGuard} from './_core/guards/can-access.guard';
import {FaqsGuard} from './_core/guards/faqs.guard';
import {FundAccessGuard} from './_core/guards/fund-access.guard';
import {TemporaryUserGuard} from './_core/guards/temporary-user.guard';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {ToastrModule} from "ngx-toastr";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    NgIdleKeepaliveModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    FontAwesomeModule,
  ],
  providers: [
    AuthGuard,
    NoAuthGuard,
    CanAccessGuard,
    FaqsGuard,
    FundAccessGuard,
    TemporaryUserGuard,
    AdminGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
