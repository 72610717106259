import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import SharedMethodsHelpers from 'src/app/_core/helpers/shared-methods.helpers';
import FundTypes from '../../../_core/contants/fund-types';
import Urls from '../../../_core/contants/urls';
import { CurrencyAndBalance, MainAssetSummary } from '../../../_core/models/fund';

@Component({
  selector: 'app-main-asset-summary',
  templateUrl: './main-asset-summary.component.html',
  styleUrls: ['./main-asset-summary.component.scss'],
})
export class MainAssetSummaryComponent implements OnInit {
  @Input() summaryData: MainAssetSummary;
  @Input() fundType: string;
  @Input() filters: any;
  @Input() otherCurrencies: CurrencyAndBalance[];
  @Input() activity = false;
  @Input() hasPrimaryCurrency: boolean;
  @Output() selectedTabEvent = new EventEmitter<string>();

  activityTabs = Urls;
  fundTypes = FundTypes;
  balance: number;
  page: string;

  constructor(private router: Router) {}

  ngOnInit(): void {
    const currency = this.otherCurrencies.find((currency) => currency.id === this.filters.currency);
    if (currency) this.balance = currency.balance;
  }

  goToActivity(selectedTab): void {
    this.selectedTabEvent.emit(selectedTab);
    this.router.navigate([`${Urls.APP}/${Urls.ACTIVITY}/${selectedTab}`]);
  }
}
