import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateCut',
})
export class TranslateCutPipe implements PipeTransform {
  transform(value: string, index: number, separator = '||'): string {
    return value.split(separator)[index];
  }
}
