import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OtherAsset, OtherAssetBalance } from '../../../_core/models/fund';
import { FundService } from '../../../_core/services/fund.service';
import { Router } from '@angular/router';
import Urls from '../../../_core/contants/urls';

@Component({
  selector: 'app-other-assets-summary',
  templateUrl: './other-assets-summary.component.html',
  styleUrls: ['./other-assets-summary.component.scss'],
})
export class OtherAssetsSummaryComponent {
  @Input() otherAssetsBalances: number;
  @Input() selectedCurrencySymbol: string;
  @Input() otherAssetBalanceList: OtherAssetBalance[];
  @Output() assetSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router) {}

  goToActivity(asset: OtherAssetBalance) {
    this.router.navigate([`${Urls.APP}/${Urls.ACTIVITY}/${Urls.SUMMARY}`], {
      queryParams: new OtherAsset(asset.internalId, asset.assetName, asset.fundCash),
    });
  }
}
