<div class="modals">
  <div class="modal-content px-3">
    <div class="modal-header py-4 d-block">
      <h4 class="text-center">{{ 'MODALS_EMBED_TITLE' | translate }}</h4>
    </div>
    <div class="modal-body">
      <div>
        <p>{{ 'MODALS_EMBED_SELECT_TEXT' | translate }}</p>
        <h5 class="font-weight-bold">{{ 'MODALS_EMBED_HTML_TEXT' | translate }}</h5>
        <hr />
        <p>{{ 'MODALS_EMBED_COPY_TEXT' | translate }}</p>
      </div>
      <div class="container mb-3">
        <div class="pretty p-default p-round p-img-label">
          <input type="radio" name="donateButton" value="light" (click)="onSelectButton('light')" />
          <div class="state p-primary">
            <label class="img-label"><img class="button-img" src="assets/donate-buttons/light_donate_button.png" /></label>
          </div>
        </div>
        <div class="pretty p-default p-round p-img-label">
          <input type="radio" name="donateButton" value="dark" (click)="onSelectButton('dark')" />
          <div class="state p-primary">
            <label class="img-label"><img class="button-img" src="assets/donate-buttons/dark_donate_button.png" /></label>
          </div>
        </div>
      </div>
      <textarea class="form-control generalInput" [ngModel]="buttonHtmlCode" readonly rows="4" maxlength="250"></textarea>
      <div class="mt-3">
        <h5 class="font-weight-bold">{{ 'MODALS_EMBED_LINK_TEXT' | translate }}</h5>
        <hr />
        <p>{{ 'MODALS_EMBED_COPY_URL_TEXT' | translate }}</p>
        <textarea class="form-control generalInput" [ngModel]="link" readonly rows="2" maxlength="250"></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="lg-button" (click)="close()">{{ 'MODALS_EMBED_CLOSE_BUTTON' | translate }}</button>
    </div>
  </div>
</div>
