<div>
  <!--Balance-->
  <div class="d-flex justify-content-between flex-wrap">
    <div>
      <ng-container *ngIf="hasPrimaryCurrency">
        <h3 class="mb-1">{{ 'SUMMARY_TOTAL_BALANCE' | translate }}: {{ balance | number : '1.2-2' | numberFormat }} {{ filters.currencySymbol }}</h3>
        <div *ngIf="summaryData; else noData">
          <h5 class="mt-4">{{ summaryData?.assetName }} {{ 'SUMMARY_BALANCE' | translate }}</h5>
          <div class="closing-balance">
            {{ summaryData.closingBalance | number : '1.2-2' | numberFormat }} {{ filters.currencySymbol }}
            <span class="small" *ngIf="summaryData.fundCash">
              ({{ 'SUMMARY_AVAILABLE' | translate }}: {{ summaryData.availableBalance | number : '1.2-2' | numberFormat }}
              {{ filters.currencySymbol }})
            </span>
          </div>
        </div>
      </ng-container>

      <ng-template #noData>
        <div *ngIf="hasPrimaryCurrency; else noPrimaryCurrency" class="mt-3">{{ 'SUMMARY_NO_BALANCE' | translate }}</div>
      </ng-template>

      <ng-template #noPrimaryCurrency>
        <div class="mt-4">{{ 'SUMMARY_NO_CURRENCY' | translate }}</div>
      </ng-template>
    </div>

    <app-currency-dropdown *ngIf="!activity" [currency]="filters.currency" [otherCurrencies]="otherCurrencies"></app-currency-dropdown>
  </div>

  <!--Table Section-->
  <div class="row mt-5" *ngIf="summaryData">
    <div class="col-sm-12">
      <h5>{{ 'SUMMARY_FUND_CASH' | translate }}</h5>
      <div class="table mt-1">
        <table class="table">
          <thead>
            <th class="table-text">{{ 'SUMMARY_TRANSLACTION_TYPE' | translate }}</th>
            <th class="text-right">{{ 'SUMMARY_AMOUNT' | translate }}</th>
          </thead>
          <tbody>
            <tr>
              <td>
                <a class="table-text-red">{{ 'SUMMARY_OPENING_BALANCE' | translate }}</a>
              </td>
              <td class="text-right table-text-black">
                {{ summaryData.openingBalance | number : '1.2-2' | numberFormat }} {{ filters.currencySymbol }}
              </td>
            </tr>
            <tr (click)="goToActivity(activityTabs.CONTRIBUTIONS)">
              <td>
                <a class="table-link">{{ 'HOME_CONTRIBUTIONS' | translate }}</a>
              </td>
              <td class="text-right table-link-black">
                {{ summaryData.donationsToMyFund | number : '1.2-2' | numberFormat }} {{ filters.currencySymbol }}
              </td>
            </tr>
            <tr *ngIf="fundType !== fundTypes.CHARITY" (click)="goToActivity(activityTabs.TRANSFERS)">
              <td>
                <a class="table-link">{{ 'HOME_TRANSFERS_TO_FUND' | translate }}</a>
              </td>
              <td class="text-right table-link-black">
                {{ summaryData.transfersMadeToThisFund | number : '1.2-2' | numberFormat }} {{ filters.currencySymbol }}
              </td>
            </tr>
            <tr (click)="goToActivity(activityTabs.GRANTS)">
              <td>
                <a class="table-link">{{ (fundType === fundTypes.CHARITY ? 'HOME_DISTRIBUTIONS' : 'HOME_GRANTS') | translate }}</a>
              </td>
              <td class="text-right table-link-black">
                {{ summaryData.grantsToCharities | number : '1.2-2' | numberFormat }} {{ filters.currencySymbol }}
              </td>
            </tr>
            <tr (click)="goToActivity(activityTabs.TRANSFERS)" *ngIf="fundType !== fundTypes.CHARITY">
              <td>
                <a class="table-link">{{ 'HOME_TRANSFERS_FROM_FUND' | translate }}</a>
              </td>
              <td class="text-right table-link-black">
                {{ summaryData.transfersMadeFromThisFund | number : '1.2-2' | numberFormat }} {{ filters.currencySymbol }}
              </td>
            </tr>
            <tr (click)="goToActivity(activityTabs.OTHER_ACTIVITIES)">
              <td>
                <a class="table-link">{{ 'SUMMARY_OTHER_ACTIVITIES' | translate }}</a>
              </td>
              <td class="text-right table-link-black">
                {{ summaryData.otherActivities | number : '1.2-2' | numberFormat }} {{ filters.currencySymbol }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
