import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '../../../environments/environment';
import { EnvironmentNames } from '../../_core/contants/environments';

@Pipe({
  name: 'fundNameFormat',
})
export class FundNameFormatPipe implements PipeTransform {
  transform(value: string): string {
    const isSinngeber = environment.environmentName === EnvironmentNames.SINNGEBER;

    if (isSinngeber) return value;

    const itemsToFilterOut = ['the', 'fund'];
    const { cleanedString, removedPortion } = this.removeParentheses(value);

    const filtered = (arr: string[]) => arr.filter((item) => !itemsToFilterOut.includes(item.toLowerCase()));
    const filteredTheFund = filtered(cleanedString.split(' '));
    let returnArr = ['The', ...filteredTheFund, 'Fund'];

    if (removedPortion) returnArr = [...returnArr, removedPortion];

    return returnArr.join(' ');
  }

  removeParentheses(inputString: string): { cleanedString: string; removedPortion: string | null } {
    // Use regex to find and remove the portion enclosed in parentheses
    const regex = /\([^)]*\)/g;
    const match = inputString.match(regex);
    const cleanedString = inputString.replace(regex, '').trim();
    return { cleanedString, removedPortion: match ? match[0] : null };
  }
}
