import { CharityProject } from './SearchCharity';
import { Fund } from './fund';

export class Charity {
  cseg5: string;
  fundNumber: string;
  fundType: string;
  status: string;
  name: string;
  value: string;
  expired: boolean;

  constructor(charityData: CharityDto | TransferCharityDto | Fund | CharityProject, expired?: boolean, isProject = false) {
    if (charityData) {
      this.cseg5 = charityData.cseg5;
      this.name = isProject ? (charityData as CharityProject).entityName : charityData.fundName;
      this.fundNumber = charityData.fundNumber;
      this.value = `${this.name} #(${this.fundNumber})`;
      this.fundType = this.setType(charityData);
      if (Object.keys(charityData).includes('status')) this.status = (charityData as CharityDto).status;
    }
    this.expired = expired;
  }

  setType(charityData: any): string {
    if (Object.keys(charityData).includes('fundType')) return charityData.fundType;
    if (Object.keys(charityData).includes('type')) return charityData.type;
  }
}

export interface CharityDto {
  cseg5: string;
  fundName: string;
  fundNumber: string;
  fundType: string;
  status: string;
}

export interface TransferCharityDto {
  cseg5: string;
  fundName: string;
  fundNumber: string;
  type: string;
}

export enum ProjectStatus {
  APPROVED = 'Project Application Approved',
}
