export enum SortByDropdownValues {
  COMPLETED_ON = 'completedOnString',
  ID = 'internalId',
  DESCRIPTION = 'description',
  TRANSACTION_TYPE = 'transactionType',
  CHARITY_NAME = 'charityName',
  RECEIVER_NAME = 'receiverFundName',
  REQUESTED_FOR = 'requestedForString',
  LOCATION = 'location',
  SUBMITTED_ON = 'submittedOnString',
  AMOUNT = 'amount',
}

export const sortByDropdownOptions = [
  { label: 'ACTIVITY_ID', value: SortByDropdownValues.ID },
  { label: 'ACTIVITY_DESCRIPTION', value: SortByDropdownValues.DESCRIPTION },
  { label: 'ACTIVITY_LOCATION', value: SortByDropdownValues.LOCATION },
  { label: 'ACTIVITY_SUBMITTED', value: SortByDropdownValues.SUBMITTED_ON },
  { label: 'ACTIVITY_COMPLETED', value: SortByDropdownValues.COMPLETED_ON },
  { label: 'GENERAL_AMOUNT', value: SortByDropdownValues.AMOUNT },
];

export const sortByDropdownOptionsPending = [
  { label: 'ACTIVITY_ID', value: SortByDropdownValues.ID },
  { label: 'ACTIVITY_TRANSACTION_TYPE', value: SortByDropdownValues.TRANSACTION_TYPE },
  { label: 'ACTIVITY_FUND', value: SortByDropdownValues.CHARITY_NAME },
  { label: 'ACTIVITY_FUND_PROJECT', value: SortByDropdownValues.RECEIVER_NAME },
  { label: 'ACTIVITY_LOCATION', value: SortByDropdownValues.LOCATION },
  { label: 'ACTIVITY_SUBMITTED', value: SortByDropdownValues.SUBMITTED_ON },
  { label: 'ACTIVITY_REQUESTED', value: SortByDropdownValues.REQUESTED_FOR },
  { label: 'GENERAL_AMOUNT', value: SortByDropdownValues.AMOUNT },
];

export const orderByDropdownOptions = [
  { label: 'ACTIVITY_SORT_ASC', value: 'ASC' },
  { label: 'ACTIVITY_SORT_DESC', value: 'DESC' },
];
