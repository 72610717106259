import { Component, OnInit } from '@angular/core';
import { EnvironmentNames } from 'src/app/_core/contants/environments';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  privacyPolicy: string;
  terms: string;
  year: number;
  isCncf: boolean;

  constructor() {}

  ngOnInit(): void {
    this.isCncf = environment.environmentName === EnvironmentNames.CNCF;
    this.privacyPolicy = environment.privacyPolicy;
    this.terms = environment.terms;
    this.year = new Date().getFullYear();
  }
}
