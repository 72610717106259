import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NoAuthGuard } from './_core/guards/no-auth.guard';
import { PageNotFoundComponent } from './_shared/page-not-found/page-not-found.component';
import Urls from './_core/contants/urls';
import { AuthGuard } from './_core/guards/auth.guard';
import { EnvironmentNames } from './_core/contants/environments';

const routes: Routes = [
  {path: '', redirectTo: Urls.APP, pathMatch: 'full'},
  {path: `${Urls.PAGES}/${Urls.USER}`, redirectTo: Urls.AUTH},
  {path: Urls.PAGES, redirectTo: Urls.APP},
  {
    path: Urls.APP,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: Urls.AUTH,
    canLoad: [NoAuthGuard],
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: Urls.PUBLIC,
    canActivate: [NoAuthGuard],
    canLoad: [NoAuthGuard],
    data: {environments: [EnvironmentNames.SWISS, EnvironmentNames.IGF, EnvironmentNames.CNCF, EnvironmentNames.GLF]},
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
