import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Configuration } from '../../app.constants';
import { Observable } from 'rxjs';

@Injectable()
export class AuthService {
  private readonly resourceUrl = '/user';

  constructor(private http: HttpClient, private apiService: ApiService, private config: Configuration) {}

  login(credentials): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const body = new HttpParams()
      .set('username', credentials.username?.trim())
      .set('password', encodeURIComponent(credentials.password?.trim()))
      .set('grant_type', 'password')
      .set('scope', 'web');

    return this.http.post(`${this.config.server}/oauth/token`, body.toString(), { headers });
  }

  logout(): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/logout`);
  }

  getUserDetails(): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/get-user-info`);
  }

  forgotPassword(body): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${this.config.server}/user/register/forgot-password`, body, { headers });
  }

  resetPassword(body): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/register/reset-password/confirm-reset`, body);
  }

  changePassword(body): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/change-password`, body);
  }

  changeTemporaryPass(body): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/temporary-password`, body);
  }

  getCurrencies(): Observable<any> {
    return this.apiService.get(this.resourceUrl + '/get-currencies');
  }
}
