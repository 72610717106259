export default class Utils {
  public static splitAndTakeFirst(item: string, separator: string): string {
    if (item.indexOf(separator) < 0) {
      return item;
    }

    return item.split(separator)[0].trim();
  }
}

export const omit = <T extends object, K extends keyof T>(obj: T, props: K[]): Omit<T, K> => {
  const newObj = { ...obj };
  props.forEach((prop) => delete newObj[prop]);
  return newObj;
};
