import {Component, OnInit} from '@angular/core';
import {TitleService} from '../../_core/services/title.service';
import PageTitles from '../../_core/contants/page-titles';

@Component({
  selector: 'app-error-page',
  templateUrl: './no-funds.component.html',
})
export class NoFundsComponent implements OnInit {

  constructor(private titleService: TitleService) {}

  ngOnInit(): void {
    this.titleService.titleChanged(PageTitles.CANNOT_ACCESS);
  }
}
