export enum Role {
  USER = 'ROLE_USER',
  TEMPORARY_USER = 'ROLE_TEMPORARY_USER',
  MASTER = 'ROLE_MASTER',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export enum AccessLevels {
  FULL = 'Full Access',
  VIEW = 'View Access',
  PROJECT_TRANSFER = 'Project Portal Transfer Access',
}
