import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/index';
import {Router} from '@angular/router';
import {PaginationService} from '../../_core/services/pagination.service';

@Component({
  selector: 'app-pagination-front',
  templateUrl: './pagination-front.component.html',
  styleUrls: ['./pagination-front.component.scss']
})
export class PaginationFrontComponent implements OnInit, OnDestroy {

  allTransactions = [];
  displayPagination = false;
  subscription: Subscription;

  pages = [];
  numberOfPages = 0;
  startIndexForPages = 0;
  currentPage = 0;

  setOfItems = [{number: 12}, {number: 24}, {number: 48}];
  selectedSetOfItems = {number: null};

  paginationData: any = {
    transactionsToDisplay: [],
    firstIndexDisplayed: 1,
    lastIndexDisplayed: 10,
    numberOfResults: 25
  };

  constructor(
    private paginationService: PaginationService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.subscription = this.paginationService.allTransactions$.subscribe(allTransactions => {
      this.allTransactions = allTransactions;
      if (allTransactions != null && allTransactions.length > 0) {
        this.selectedSetOfItems.number = 10;
      }
      this.displayPagination = this.shouldDisplayPagination();
      this.initItemsDisplayed();
      this.setPage(0);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initItemsDisplayed() {
    if (this.allTransactions) {
      if (this.displayPagination) {
        this.getSetOfItemsForLargeList();
        this.setPaginationData(this.allTransactions.slice(0, this.selectedSetOfItems.number), 1, this.selectedSetOfItems.number, this.allTransactions.length);
        this.computePages();
      } else {
        this.setPaginationData(this.allTransactions, 1, this.allTransactions.length, this.allTransactions.length);
      }

      this.setTransactionsToDisplay();
    }
  }

  computePages() {
    this.pages = [];
    this.numberOfPages = Math.ceil(this.allTransactions.length / this.selectedSetOfItems.number);
    let i = 0;
    while (i < this.numberOfPages) {
      this.pages.push(i++);
    }
  }

  setPaginationData(transactionsToDisplay: any, firstIndex: number, lastIndex: number, results: number) {
    if (results === 0) {
      firstIndex = 0;
    }
    this.paginationData = {
      transactionsToDisplay: transactionsToDisplay,
      firstIndexDisplayed: firstIndex,
      lastIndexDisplayed: lastIndex,
      numberOfResults: results
    };
  }

  setPage(selectedPage) {
    if (this.currentPage === 0 && selectedPage === 0) {
      return;
    }
    if (this.currentPage === this.numberOfPages - 1 && selectedPage === this.numberOfPages - 1) {
      return;
    }

    this.currentPage = selectedPage;
    this.setStartIndexForPagesForNextAndSet();
    this.computePaginationData();
    this.setTransactionsToDisplay();
    this.scrollToTop();
  }

  prevPage() {
    if (this.currentPage === 0) {
      return;
    }

    if (this.currentPage > 0) {
      this.currentPage--;
    }

    if (this.currentPage < 3) {
      this.startIndexForPages = 0;
    } else if (this.currentPage === this.numberOfPages - 2) {
      this.startIndexForPages = this.currentPage - 3;
    } else {
      this.startIndexForPages = this.currentPage - 2;
    }
    this.computePaginationData();
    this.setTransactionsToDisplay();
    this.scrollToTop();
  }

  nextPage() {
    if (this.currentPage === this.numberOfPages - 1) {
      return;
    }

    if (this.currentPage < this.numberOfPages - 1) {
      this.currentPage++;
    }

    this.setStartIndexForPagesForNextAndSet();
    this.computePaginationData();
    this.setTransactionsToDisplay();
    this.scrollToTop();
  }

  computePaginationData() {
    const firstIndexOnPage = this.currentPage * this.selectedSetOfItems.number;
    const firstIndex = firstIndexOnPage + 1;
    let lastIndex = firstIndexOnPage + Number(this.selectedSetOfItems.number);
    const numberOfResults = this.allTransactions.length;

    if (lastIndex > numberOfResults) {
      lastIndex = numberOfResults;
    }
    const transactionsToDisplay = this.allTransactions.slice(firstIndexOnPage, firstIndexOnPage +
      Number(this.selectedSetOfItems.number));

    this.setPaginationData(transactionsToDisplay, firstIndex, lastIndex, numberOfResults);
  }

  setStartIndexForPagesForNextAndSet() {
    if (this.currentPage <= 2) {
      this.startIndexForPages = 0;
    } else if (this.currentPage + 1 >= this.numberOfPages) {
      this.startIndexForPages = this.numberOfPages - 5 >= 0 ? this.numberOfPages - 5 : 0;
    } else {
      this.startIndexForPages = this.currentPage - 2;
    }
  }

  selectNumberOfItems(selected) {
    this.currentPage = 0;
    this.selectedSetOfItems = {number: selected};
    this.computePaginationData();
    this.setTransactionsToDisplay();
    this.computePages();
    this.scrollToTop();
    this.startIndexForPages = 0;
  }

  // getSetOfItemsForSmallList() {
  //   if (this.allTransactions.length >= 10) {
  //     this.setOfItems = [{number: 5}, {number: 10}];
  //   } else if (this.allTransactions.length >= 15) {
  //     this.setOfItems = [{number: 5}, {number: 10}, {number: 15}];
  //   } else {
  //     this.setOfItems = [{number: 5}];
  //   }
  // }

  getSetOfItemsForLargeList() {
    if (this.allTransactions.length >= 25) {
      this.setOfItems = [{number: 12}, {number: 25}];
    }
    if (this.allTransactions.length >= 50) {
      this.setOfItems = [{number: 12}, {number: 25}, {number: 50}];
    } else {
      this.setOfItems = [{number: 12}];
    }
  }

  setTransactionsToDisplay() {
    this.paginationService.transactionsDisplayedChanged(this.paginationData);
  }

  shouldDisplayPagination(): boolean {
    return this.allTransactions && this.allTransactions.length > this.selectedSetOfItems.number;
  }

  scrollToTop() {
    const x = document.querySelector('#top');
    if (x) {
      const y = x.getBoundingClientRect().top + window.scrollY; // -
      // x.scrollIntoView() // +
      window.scrollTo({top: y, behavior: 'smooth'}); // -
    }
  }

}
