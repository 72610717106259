<div class="row justify-content-center" *ngIf="displayPagination">
  <div>
    <ul class="pagination pagination-md">
      <!-- First -->
      <li class="page-item" [ngClass]="currentPage == 0 ? 'disabled' : ''">
        <a class="page-link" (click)="setPage(0)">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>

      <!-- Prev -->
      <li class="page-item" [ngClass]="currentPage == 0 ? 'disabled' : ''">
        <a class="page-link" (click)="prevPage()">
          <span aria-hidden="true">&lsaquo;</span>
        </a>
      </li>

      <!-- Pages -->
      <li
        class="page-item"
        *ngFor="let n of pages.slice(startIndexForPages, startIndexForPages + 5)"
        [ngClass]="n == currentPage ? 'active' : ''"
        (click)="setPage(n)"
      >
        <a class="page-link">{{ n + 1 }}</a>
      </li>

      <!-- Next -->
      <li class="page-item" [ngClass]="currentPage == numberOfPages - 1 ? 'disabled' : ''">
        <a class="page-link" (click)="nextPage()" aria-label="Next">
          <span aria-hidden="true">&rsaquo;</span>
        </a>
      </li>

      <!-- Last -->
      <li class="page-item" [ngClass]="currentPage == numberOfPages - 1 ? 'disabled' : ''">
        <a class="page-link" (click)="setPage(numberOfPages - 1)">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </div>

  <div *ngIf="setOfItems.length > 1">
    <select #o class="form-control ml-3" (change)="selectNumberOfItems(o.value)">
      <option *ngFor="let nr of setOfItems; let i = index" [selected]="i == 0" [value]="nr.number">
        {{ 'GENERAL_DISPLAY' | translate }} {{ nr.number }}
      </option>
    </select>
  </div>
</div>
