import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss'],
})
export class CustomCheckboxComponent implements OnInit {
  @Input() text;
  @Input() img;
  @Input() value;
  @Input() checked = false;

  @Output() imgClick = new EventEmitter();
  @Output() radioClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
