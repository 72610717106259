import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import Urls from '../contants/urls';
import StorageHelper from '../helpers/storage.helper';
import SharedMethodsHelpers from '../helpers/shared-methods.helpers';

@Injectable({
  providedIn: 'root',
})
export class TemporaryUserGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const tokenInfo = SharedMethodsHelpers.decodeToken();
    if (!tokenInfo.shouldResetPassword && tokenInfo.doesPinNumberExist) {
      return true;
    }
    this.router.navigate([`${Urls.APP}/${Urls.CHANGE_PASSWORD}`]);
    return false;
  }
}
