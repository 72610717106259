export enum RECURRENCE_PATTERN {
  WEEKLY = 'WEEKLY',
  BI_WEEKLY = 'BI_WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  ANNUALLY = 'ANNUALLY',
}

export enum RECURRENCE_RANGE_END {
  ENDBY = 'endBy',
  ENDAFTER = 'endAfter',
  NOEND = 'noEnd',
}
