import { orderBy } from 'lodash';

import { SortOptions } from './sort-header.component';

export function sortItems(items: any[], { order, sort }: SortOptions, secondarySort: string = 'completedOnString'): any[] {
  const sortOrder = order === 'ASC' ? 'asc' : 'desc';
  const internalIdSort = (item: any) => +item.internalId;

  switch (sort) {
    case 'internalId':
      return orderBy(items, [internalIdSort, secondarySort], [sortOrder, 'desc']);
    case 'completedOnString':
      return orderBy(items, [sort, internalIdSort], [sortOrder, 'desc']);
    case 'charityName':
      return orderBy(
        items,
        [(item) => (item.project ? item.charityName : item.receiverFundName), secondarySort, internalIdSort],
        [sortOrder, 'desc', 'desc']
      );
    case 'receiverFundName':
      return orderBy(
        items,
        [(item) => (item.project ? item.receiverFundName : item.charityName), secondarySort, internalIdSort],
        [sortOrder, 'desc', 'desc']
      );
    default:
      return orderBy(items, [sort, secondarySort, internalIdSort], [sortOrder, 'desc', 'desc']);
  }
}
