import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { SortOptions } from '../sort-header/sort-header.component';
import { sortItems } from '../sort-header/sort.helper';

@Injectable()
export class ActivityTableSortService {
  currentOptions$ = new BehaviorSubject<SortOptions>(null);
  items: any[];
  apiReqMapping = {
    completedOnString: 'date',
    internalId: 'documentNumber',
    description: 'memo',
    location: 'subsidiary',
    submittedOnString: 'submittedOn',
    amount: 'amount',
  };

  init(items: any[]): void {
    this.items = items;
  }

  sortItems(items: any[], { order, sort }: SortOptions): any[] {
    return sortItems(items, { order, sort });
  }

  mapSortToApiReq(): { order: string; sort: string } {
    const { order, sort } = this.currentOptions$.value;
    return { order, sort: this.apiReqMapping[sort] };
  }
}
