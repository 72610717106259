import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigSetting } from 'src/app/pages/admin-welcome/global-config/global-config.model';

@Injectable({
  providedIn: 'root',
})
export class GlobalConfigService {
  _globalConfig: ConfigSetting;
  globalConfig$: BehaviorSubject<ConfigSetting> = new BehaviorSubject<ConfigSetting>(null);

  constructor() {}

  updateGlobalConfig(config: ConfigSetting): void {
    this._globalConfig = config;
    this.globalConfig$.next(this._globalConfig);
  }

  getGlobalConfig(): ConfigSetting {
    return this._globalConfig;
  }
}
