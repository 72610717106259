import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Modals } from '../contants/modals';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  private modalSource = new Subject<any>();
  private modalResponse = new Subject<any>();

  modal$ = this.modalSource.asObservable();
  modalResponse$ = this.modalResponse.asObservable();

  params: any = null;
  modals = Object.values(Modals);

  openModal(modalName, params?) {
    if (this.modals.indexOf(modalName) === -1) {
      console.error('Modal ' + modalName + ' not found!');
      return;
    }

    this.params = params;
    this.modalSource.next(modalName);
  }

  closeModal() {
    this.modalSource.next('close');
  }

  emitResponse(response): void {
    this.modalResponse.next(response);
  }
}
