import Urls from './urls';

export interface ActivityTab {
  displayName: string;
  name: string | Urls;
  active: boolean | undefined;
}

export enum NAV_LINK {
  HOME,
  CONTRIBUTE,
  FX_CONVERSION,
  ACTIVITY,
  TRANSFER,
  GRANT,
  DISTRIBUTE,
}

export const ACTIVITY_TABS: ActivityTab[] = [
  {
    displayName: 'ACTIVITY_SUMMARY',
    name: Urls.SUMMARY,
    active: undefined,
  },
  {
    displayName: 'ACTIVITY_CONTRIBUTIONS',
    name: Urls.CONTRIBUTIONS,
    active: undefined,
  },
  {
    displayName: 'ACTIVITY_TRANSFERS',
    name: Urls.TRANSFERS,
    active: undefined,
  },
  {
    displayName: 'ACTIVITY_GRANTS',
    name: Urls.GRANTS,
    active: undefined,
  },
  {
    displayName: 'ACTIVITY_OTHER',
    name: Urls.OTHER_ACTIVITIES,
    active: undefined,
  },
  {
    displayName: 'ACTIVITY_PENDING',
    name: Urls.PENDING,
    active: undefined,
  },
];

export const TRANSACTION_TABS = [
  {
    displayName: 'ACTIVITY_TRANSACTION_ALL',
    name: Urls.ALL,
    active: undefined,
  },
  {
    displayName: 'ACTIVITY_CONTRIBUTIONS',
    name: Urls.CONTRIBUTIONS,
    active: undefined,
  },
  {
    displayName: 'ACTIVITY_TRANSFERS',
    name: Urls.TRANSFERS,
    active: undefined,
  },
  {
    displayName: 'ACTIVITY_GRANTS',
    name: Urls.GRANTS,
    active: undefined,
  },
  {
    displayName: 'ACTIVITY_OTHER',
    name: Urls.OTHER_ACTIVITIES,
    active: undefined,
  },
];

export const HEADER_NAV_LINKS = [
  {
    name: NAV_LINK.HOME,
    title: 'HEADER_HOME',
    appLink: Urls.HOME,
    popoverMessage: 'HEADER_HOME_TOOLTIP',
    iconClass: 'home-icon',
    visibility: true,
  },
  {
    name: NAV_LINK.CONTRIBUTE,
    title: 'HEADER_CONTRIBUTE',
    appLink: Urls.DONATE,
    popoverMessage: 'HEADER_CONTRIBUTE_TOOLTIP',
    iconClass: 'contribute-icon',
    visibility: true,
  },
  {
    name: NAV_LINK.GRANT,
    title: 'HEADER_GRANT',
    appLink: `${Urls.TRANSACTION}/${Urls.GRANT}`,
    popoverMessage: 'HEADER_GRANT_TOOLTIP',
    iconClass: 'grant-icon',
    visibility: true,
  },
  {
    name: NAV_LINK.DISTRIBUTE,
    title: 'HEADER_DISTRIBUTE',
    appLink: `${Urls.TRANSACTION}/${Urls.DISTRIBUTE}`,
    popoverMessage: 'HEADER_DISTRIBUTE_TOOLTIP',
    iconClass: 'grant-icon',
    visibility: true,
  },
  {
    name: NAV_LINK.TRANSFER,
    title: 'HEADER_TRANSFER',
    appLink: `${Urls.TRANSACTION}/${Urls.TRANSFER}`,
    popoverMessage: 'HEADER_TRANSFER_TOOLTIP',
    iconClass: 'invest-icon',
    visibility: true,
  },
  {
    name: NAV_LINK.FX_CONVERSION,
    title: 'HEADER_FX_CONVERSION',
    appLink: Urls.FX_CONVERSION,
    popoverMessage: 'HEADER_FX_CONVERSION_TOOLTIP',
    iconClass: 'fx-icon',
    visibility: true,
  },
  {
    name: NAV_LINK.ACTIVITY,
    title: 'HEADER_ACTIVITY',
    appLink: Urls.ACTIVITY,
    popoverMessage: 'HEADER_ACTIVITY_TOOLTIP',
    iconClass: 'history-icon',
    visibility: true,
  },
];
