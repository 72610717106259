import { Component, OnDestroy, OnInit } from '@angular/core';
import { Fund } from '../../_core/models/fund';
import { FundService } from '../../_core/services/fund.service';
import { TitleService } from '../../_core/services/title.service';
import FundTypes from '../../_core/contants/fund-types';
import PageTitles from 'src/app/_core/contants/page-titles';
import { Subscription } from 'rxjs';
import { CharityFaqs, DafFaqs, CNCFDafFaqs } from './Faqs';
import { EnvironmentNames } from 'src/app/_core/contants/environments';
import { Configuration } from 'src/app/app.constants';

@Component({
  // CSS
  styles: [
    `
      .q {
        color: #1d1d1d;
      }

      .a {
        color: rgba(29, 29, 29, 0.7);
        text-align: justify;
      }
    `,
  ],

  // HTML
  template: `
    <div class="mb-5" *ngFor="let chapter of allFaqs">
      <h5 class="q">{{ 'FAQ_QUESTIONS_' + chapter | translate }}</h5>
      <div class="w-100">
        <p class="a" [innerHTML]="'FAQ_ANSWERS_' + chapter | translate"></p>
      </div>
    </div>
  `,
})
export class FaqComponent implements OnInit, OnDestroy {
  fundSubscription: Subscription;
  currentFund: Fund;
  allFaqs: string[];
  environments = EnvironmentNames;
  isCNCF: boolean;
  environment: string;
  FaqsDaf: string[] = DafFaqs;

  constructor(private fundService: FundService, private titleService: TitleService, private config: Configuration) {
    this.environment = this.config.environmentName;
    this.FaqsDaf = this.environment === this.environments.CNCF ? CNCFDafFaqs : DafFaqs;
    this.fundSubscription = this.fundService.fund$.subscribe((fund) => (this.allFaqs = fund.fundType !== FundTypes.DAF ? CharityFaqs : this.FaqsDaf));
  }

  ngOnInit(): void {
    this.titleService.titleChanged(PageTitles.FAQS);
  }

  ngOnDestroy(): void {
    this.fundSubscription.unsubscribe();
  }
}
