import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toSnakeCase',
})
export class ToSnakeCasePipe implements PipeTransform {
  transform(value: string): string {
    return value ? value.replace(/([a-z0-9])([A-Z])/g, '$1_$2').toLowerCase() : value;
  }
}
