import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EnvironmentNames } from 'src/app/_core/contants/environments';
import { environment } from 'src/environments/environment';
import SharedMethodsHelpers from '../../_core/helpers/shared-methods.helpers';
import { CurrencyAndBalance, Fund } from '../../_core/models/fund';
import { FundService } from '../../_core/services/fund.service';
import { UserService } from '../../_core/services/user.service';

@Component({
  selector: 'app-currency-dropdown',
  templateUrl: './currency-dropdown.component.html',
  styleUrls: ['./currency-dropdown.component.scss'],
})
export class CurrencyDropdownComponent implements OnChanges {
  @Input() otherCurrencies: CurrencyAndBalance[];
  @Input() currency: string;

  environment: string;
  environmentNames = EnvironmentNames;

  constructor(private fundService: FundService, private userService: UserService) {
    this.environment = environment.environmentName;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currency.currentValue !== changes.currency.previousValue) {
      this.userService.setSelectedCurrencyId(this.currency);
    }
  }

  changeCurrency(currencyId: string): void {
    const fund = { ...this.fundService.currentFund };
    const currency: CurrencyAndBalance = SharedMethodsHelpers.getCurrency(this.otherCurrencies, currencyId);
    fund.currency = currencyId;
    fund.currencySymbol = currency.symbol;
    fund.hasFundCash = currency.hasFundCash;
    fund.availableAmount = currency.availableAmountFundCash;
    fund.closingBalance = currency.availableAmountFundCash;
    this.fundService.fundChanged(fund);
    this.userService.setSelectedCurrencyId(currencyId);
  }
}
